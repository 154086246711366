//import liraries
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import '../dashboard.css';
import { useAuth } from '../../auth/core/Auth';
import ShowError from '../../../../_metronic/partials/content/toasts/ShowError';
import EventsPopup from '../../../../_metronic/partials/modals/JobFunnelsModels/EventsPopup';

const API_URL = process.env.REACT_APP_API_URL
const GET_EVENTS_URL = `${API_URL}/analytics/events`;

// create a component
const UpcomingEvents = () => {
    const { currentUser, auth, logout } = useAuth()
    const [date, setDate] = useState(new Date());
    const [eventsList, setEventsList] = useState<any>();
    const [eventDates, setEventDates] = useState<any>([new Date()]);
    // const eventDates = [new Date('2024-04-10'), new Date('2024-04-15'), new Date('2024-04-20')];
    const [filteredEvents, setFilteredEvents] = useState<any>([]);
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [message, setMessage] = useState('');
    const [showEventsPopup, setShowEventsPopup] = useState(false);

    const tileDisabled = ({ date, view }) => {
        // const currentDate = new Date();
        // return view === 'month' && date < currentDate;

        return !eventDates.find(d => d.toDateString() === date.toDateString());
    };

    const tileClassName = ({ date }) => {
        return eventDates?.find((d) => d.toDateString() === date.toDateString()) ? 'event-date' : null;
    };

    const handleDateChange = (value) => {
        // // setDate(value);
        // console.log('DateValue: ', value)
        // setShowEventsPopup(true);

        setDate(value);
        const filtered = eventsList.filter(event =>
            new Date(event.scheduled_time).toDateString() === value.toDateString()
        );
        setFilteredEvents(filtered);
        setShowEventsPopup(true);

    };

    useEffect(() => {
        (() => {
            getEventsList();
        })()
    }, []);

    const getEventsList = async () => {
        let url = GET_EVENTS_URL;
        if (currentUser?.role === 'ta_lead') {
            url = `${url}?ta_lead_id=${currentUser?.user_id}`
        }
        if (currentUser?.role === 'recruiter') {
            url = `${url}?recruiter_id=${currentUser?.user_id}`
        }

        await axios.get(url).then((res) => {

            let data = res?.data;
            let allEvents = data?.data;
            setEventsList(allEvents);
            if (allEvents !== null && allEvents !== undefined && allEvents.length > 0) {
                let dates: any = [];
                allEvents.map((event, index) => {
                    let date = event?.scheduled_time;
                    if (date) {
                        dates.push(new Date(date));
                    }
                });
                setEventDates(dates);
            }

        }).catch((error) => {
            if (error.response && error.response.status === 400) {
                setMessage(error.response.data.message)
                setShowErrorMessage(true)
            } else if (
                error.response &&
                error.response.status === 401 &&
                error.response.data &&
                error.response.data.name === 'TokenExpiredError'
            ) {
                logout()
            } else if (error.response && error.response.data && error.response.data.error) {
                // Handle error message from the API
                if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                    logout()
                } else {
                    setMessage(error.response.data.error.message)
                    setShowErrorMessage(true)
                }
            } else {
                setMessage(error.message)
                setShowErrorMessage(true)
            }
        });
    }

    return (
        <div >
            <div className='d-flex align-items-center justify-content-center'>
                <Calendar
                    value={date}
                    onChange={handleDateChange}
                    tileDisabled={tileDisabled}
                    tileClassName={tileClassName}
                />
            </div>
            {showEventsPopup && <EventsPopup events={filteredEvents} selectedDate={date} handleClose={() => setShowEventsPopup(false)} />}
            {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}
        </div>
    );
};

//make this component available to the app
export default UpcomingEvents;
