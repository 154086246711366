//import liraries
import React, { useState } from 'react';
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'

import './ModelStyles.css'
import imagePath from '../../../../constants/imagePath';
import { getFormattedDate } from '../../../../constants/CommonFunctions';

const modalsRoot = document.getElementById('root-modals') || document.body

// create a component
const EventsPopup = ({ events, selectedDate, handleClose, ...props }) => {
    
    const formatTime = (dateString) => {
        const date = new Date(dateString);
        let hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        const strMinutes = minutes < 10 ? '0' + minutes : minutes;
        const strTime = hours + ':' + strMinutes + ' ' + ampm;
        return strTime;
    };

    return createPortal(

        <Modal

            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-600px'
            show={true}
            onHide={handleClose}
            backdrop={true}
        >
            <>
                <div>
                    <div className=' no-header-model123 pb-8'>
                        <div className='modal-header no-header-model123'>
                            <div className='d-flex align-items-center justify-content-center'>
                                <img src={imagePath.ic_calendar_orange} width={44} height={44} />
                                <div className='ms-4 d-flex flex-column'>
                                    <h4 className='form-label fw-bolder fs-3'>Event Viewer</h4>
                                    <label className='fw-normal fs-6' >{getFormattedDate(selectedDate)}</label>
                                </div>

                            </div>
                            <div className='btn btn-sm btn-icon btn-active-color-primary' style={{ marginTop: '-2rem' }} onClick={handleClose} >

                                <img alt='' src={imagePath.ic_close}></img>
                            </div>
                        </div>

                        {(events && events.length > 0) ? events.map((event, index) => {
                            const interviewer = event?.interviewer
                            return (
                                <div key={index} className='d-flex card-bg shadow-sm mb-4 mx-10 py-2 px-4'>
                                    <img src={imagePath.ic_panel_members} width={34} height={34} />
                                    <div className='d-flex flex-column ms-4 mt-2'>
                                        <div className='d-flex align-items-center'>
                                            <div className='fs-5 fw-bolder me-4'>{event?.format}</div>
                                            <div className='fs-5 fw-bolder'>{formatTime(event?.scheduled_time)}</div>
                                        </div>
                                        <div className='d-flex align-items-center mt-2'>
                                            <img src={interviewer?.profile_pic ? interviewer?.profile_pic : imagePath.userIcon} width={44} height={44} style={{ borderRadius: '50%', objectFit: 'contain' }} />
                                            <div className='ms-2'>
                                                <div className='fs-6 fw-bolder'>{interviewer?.name}</div>
                                                <div className='fs-6 fw-normal'>{interviewer?.email}</div>
                                                <div className='fs-7 fw-normal'>Interviewer</div>
                                            </div>
                                        </div>
                                        <div className={`${event?.format === 'Online Interview' ? 'cursor-pointer' : ''} fs-6 fw-normal`} style={{ color: '#EC4A0A' }}
                                            onClick={() => {
                                                if (event?.format === 'Online Interview' && event?.interview_address) {
                                                    window.open(event.interview_address, '_blank');
                                                }
                                            }}
                                        >
                                            Meet Address: {event?.interview_address}
                                        </div>
                                    </div>
                                </div>
                            )
                        }) : (<div></div>)}

                    </div>

                </div>
            </>
        </Modal>,
        modalsRoot
    )
};

//make this component available to the app
export default EventsPopup;
