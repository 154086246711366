//import liraries
import React, { Component, useEffect, useState } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import ActionSuccessPopup from '../../../../_metronic/partials/modals/JobFunnelsModels/ActionSuccessPopup';
import ActionFailedPopup from '../../../../_metronic/partials/modals/JobFunnelsModels/ActionFailedPopup';
import ShowError from '../../../../_metronic/partials/content/toasts/ShowError';
import axios from 'axios';


// create a component
const ActionStatus = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [status, setStatus] = useState('');
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [message, setMessage] = useState('')

    useEffect(() => {
        (async () => {
            hitApi();
        })();
    },[])

    const hitApi = async () => {
        const searchParams = new URLSearchParams(location.search);
        const api = searchParams.get('api');
        console.log('API_URL: ', api);
        if (!api) {
            setMessage('API URL not provided');
            setShowErrorMessage(true);
            return;
        }

        // Remove the 'api' parameter
        searchParams.delete('api');

        // Append remaining query parameters to the base API URL
        const url = `${api}?${searchParams.toString()}`;
        // console.log('publicPageAPI_URL: ', url)
        await axios.get(url).then((res) => {

            setStatus('success');

        }).catch((error) => {
            setStatus('failed');
            if (error.response && error.response.status === 400) {
                // showToast(error.response.data.message)
                setMessage(error.response.data.message)
                setShowErrorMessage(true)
            } else if (
                error.response &&
                error.response.status === 401 &&
                error.response.data &&
                error.response.data.name === 'TokenExpiredError'
            ) {
                // logout()
                navigate('/auth', { replace: true });
            } else if (error.response && error.response.data && error.response.data.error) {
                if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                    // logout()
                    navigate('/auth', { replace: true });
                } else {
                    setMessage(error.response.data.error.message)
                    setShowErrorMessage(true)
                }
            } else {
                if (error.response.status === 401) {
                    setMessage('User not authenticated, Please login and try again')
                    setShowErrorMessage(true)
                    setTimeout(() => {
                        navigate('/auth', { replace: true });
                    }, 3000); // 3000 milliseconds = 3 seconds
                } else {
                    setMessage(error.message)
                    setShowErrorMessage(true)
                }
            }
        })


    }

    return (
        <div >

            {status === 'success' && (
                <ActionSuccessPopup
                    message={''}
                    handleClose={() => {
                        // setShowSuccessPopup(false)
                        // redirect to auth page
                        navigate('/auth', { replace: true });
                    }}
                />
            )}

            {status === 'failed' && (
                <ActionFailedPopup
                    message={''}
                    handleClose={() => {
                        // setShowErrorPopup(false)
                        // redirect to auth page
                        navigate('/auth', { replace: true });
                    }}
                />
            )}

            {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}

        </div>
    );
};

//make this component available to the app
export default ActionStatus;
