import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { GoogleLogin } from '@react-oauth/google'
import jwt_decode from 'jwt-decode'

import { getUserByToken, login, cvcheck } from '../core/_requests'
import { useAuth } from '../core/Auth'
import icOpenEye from '../images/ic_open_eye.png';
import icCloseEye from '../images/ic_close_eye.png';
import unchecked from '../images/check-box-outline-blank-black.png'
import checked from '../images/tick.png'
import letterboxImage from '../images/mail.png'
import './Login.css'
import ReactDOM from 'react-dom'
import CustomToast from '../../../../_metronic/partials/content/toasts/CustomToast'
import { isCandidatePath } from '../../../../constants/CommonFunctions'
import imagePath from '../../../../constants/imagePath'
import CryptoJS from 'crypto-js';

const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID ?? 'google-client-id'
const secretKey = process.env.REACT_APP_SECRET_KEY
const hostname = window.location.hostname
// console.log('hostname: ', hostname);
let signUp = isCandidatePath(hostname);
// let signUp = false
// if (
//   hostname === 'localhost' ||
//   hostname === 'bop-candidate-qa.isaacscode.com' ||
//   hostname === 'qa-candidate.bop.com.pk' ||
//   hostname === 'candidate.bop.com.pk' ||
//   hostname === 'democandidate.isaacscode.com'
// ) {
//   signUp = true
// }

// console.log('signUp: ', signUp);

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(8, 'Minimum 8 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    // .min(12, 'Minimum 12 symbols')
    // .max(50, 'Maximum 50 symbols')
    .matches(
      // /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&-_])[A-Za-z\d@$!%*?&-_]{12,}$/,
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]\S{12,}$/,
      'Password should have at least 12 characters, 1 capital letter, 1 small letter, numbers, 1 or more special characters and no white spaces'
    )
    .required('Password is required'),
})

export function Login() {

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false);
  const { saveAuth, setCurrentUser } = useAuth()
  const [Model, setModel] = useState(false)
  const [checkedState, setCheckedState] = useState(false)

  function encryptPassword(password, secretKey) {
    if (!secretKey) {
      throw new Error('Encryption key is not defined');
    }
    return CryptoJS.AES.encrypt(password, secretKey).toString();
  }

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      var EmailSetting
      var PasswordSetting
      EmailSetting = values.email
      PasswordSetting = values.password
      PasswordSetting = encryptPassword(PasswordSetting, secretKey);
      const credential = 'false';
      try {
        const { data: auth } = await login(EmailSetting, PasswordSetting, credential)

        // console.log('authData: ', auth)

        const { data: user } = await getUserByToken(auth.token)

        // if (user?.role === 'candidate' || signUp) {
        if (signUp) {
          const socialAuth = user?.social_auth;
          const confirmed = user?.confirmed;
          if (socialAuth === false && confirmed === false) {
            navigate(`/auth/account/not_verified/${encodeURIComponent(EmailSetting)}`, {
              replace: true
            });
            return;
          } else {
            const resp = await cvcheck(user?.user_id, auth.token)
            user.cvcheck = resp?.data?.value
            saveAuth(auth)
            setCurrentUser(user)
          }

          // } else {
          //   window.alert('Candidate can not login to recruitment portal');
          // }
        } else {
          if (user?.role !== 'candidate') {
            saveAuth(auth)
            let userOrganization = auth?.user_organization;
            let organization = userOrganization?.organization;
            user['organization_uuid'] = organization?.organization_uuid;
            setCurrentUser(user)
          } else {
            showToast('Candidates can not login here.');
          }
        }

      } catch (error: any) {
        if (error.response && error.response.status === 400) {
          const errorMessage = error.response.data.message;
          console.log('LoginError:', errorMessage);
          showToast(errorMessage);
        } else if (error.response && error.response.data && error.response.data.error) {
          // Handle error message from the API
          // showToast(error.response.data.error.message);
          showToast(error.response.data.error.message);

          // handleClose();
        } else {
          console.log('An error occurred:', error.message);
          showToast(error.message);
        }

        setModel(true)
        console.error(error)
        saveAuth(undefined)
        setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
        setTimeout(() => {
          setModel(false)
        }, 3000)
      } finally {
        setLoading(false)
      }
    },
  })

  const checkedStateFunction = () => {
    setCheckedState(!checkedState)
  }

  async function loginUser(email, password, credential) {
    setLoading(true)
    try {
      const { data: auth } = await login(email, password, credential)
      // saveAuth(auth)

      const { data: user } = await getUserByToken(auth.token)

      // if (user?.role === 'candidate') {
      if (signUp) {
        const resp = await cvcheck(user?.user_id, auth.token)
        user.cvcheck = resp.data.value
        saveAuth(auth)
        setCurrentUser(user)
      }
      // }
      setLoading(false)
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        const errorMessage = error.response.data.message;
        console.log('LoginError:', errorMessage);
        showToast(errorMessage);
      } else if (error.response && error.response.data && error.response.data.error) {
        // Handle error message from the API
        showToast(error.response.data.error.message);
        // handleClose();
      } else {
        console.log('An error occurred:', error.message);
        showToast(error.message);
      }
      setModel(true)
      console.log('Error')
      console.error(error)
      saveAuth(undefined)
      setLoading(false)
      setTimeout(() => {
        setModel(false)
      }, 3000)
    }
  }

  const showToast = (message) => {
    // Show the custom toast
    const toastContainer = document.getElementById('custom-toast-container');
    if (toastContainer) {

      toastContainer.innerHTML = ''; // Clear any existing toast
      const customToast = document.createElement('div');
      toastContainer.appendChild(customToast);

      ReactDOM.render(<CustomToast message={message} />, customToast);
      customToast.scrollIntoView({ behavior: 'smooth', block: 'end' }); // Scroll to the toast message

      setTimeout(() => {
        customToast.remove(); // Remove the toast after a certain duration
      }, 3000);
    }
  };

  return (
    <>
      <form
        className='form w-100 aaligndivCenter'
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_login_signin_form'
      >
        {/* begin::Heading */}
        <div className='text-center'>
          <h1 className=' Registration-text main-heading mb-3'>{!signUp ? 'Recruitment Portal' : 'Log In'}</h1>
          <div className='text-white my-10 fw-semibold labels'>{!signUp ? 'Find and Hire Talent' : 'Find your dream job in just one click'}</div>
        </div>
        {/* begin::Heading */}
        <div className='widthHeightSettingForm'>
          {/* begin::Form group */}
          <div className='fv-row mb-4'>
            <label className='form-label labeldfa mb-3'>Email Address</label>
            <div className='grp-image-input backinpput-color'>
              <input
                style={{ height: '56px' }}
                placeholder='Please Enter your Email'
                {...formik.getFieldProps('email')}
                className={clsx(
                  'form-control no-border',
                  { 'is-invalid': formik.touched.email && formik.errors.email },
                  {
                    'is-valid': formik.touched.email && !formik.errors.email,
                  }
                )}
                type='email'
                name='email'
                autoComplete='off'
              />
              <img alt='' src={imagePath.ic_mail}></img>
              {/* <i className=' custom-class bi-envelope'></i> */}
            </div>
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <span className='text-white' role='alert'>
                  {formik.errors.email}
                </span>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className='fv-row mb-3'>
            <label className='form-label labeldfa mb-3'>Password</label>
            <div className='grp-image-input'>
              <input
                style={{ height: '56px' }}
                type={showPassword ? 'text' : 'password'}
                autoComplete='off'
                placeholder='Enter your Password'
                {...formik.getFieldProps('password')}
                className={clsx(
                  'form-control no-border',
                  {
                    'is-invalid': formik.touched.password && formik.errors.password,
                  },
                  {
                    'is-valid': formik.touched.password && !formik.errors.password,
                  }
                )}
              />

              <img alt='' src={showPassword ? imagePath.ic_eye_open : imagePath.ic_eye_close} style={{ cursor: 'pointer' }} onClick={() => setShowPassword(!showPassword)} />
              {/* <img alt='' src={imagePath.ic_lock}></img> */}
            </div>
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>

                  {/* <ul>
                    {formik.errors.password.split('\n').map((error, index) => (
                      <li key={index}><span className='text-white' role='alert'>
                        {error}
                      </span></li>
                    ))}
                  </ul> */}

                  <span className='text-white' role='alert'>
                    {formik.errors.password}
                  </span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Wrapper */}
          <div className='d-flex flex-stack flex-wrap  fs-base fw-semibold mt-3 mb-4'>
            <div className='form-check dflex-checkbox-logged-in'>
              <div onClick={() => checkedStateFunction()}>
                {checkedState === false ? (
                  <>
                    {' '}
                    <img alt='' src={unchecked}></img>
                  </>
                ) : (
                  <>
                    {' '}
                    <img alt='' src={checked}></img>
                  </>
                )}
              </div>

              <label className='form-check-label forgot-pass'>Keep me logged in</label>
            </div>
            <div />

            {/* begin::Link */}
            <Link to='/auth/forgot-password' className='link- forgot-pass text-white'>
              <u>Forgot Password?</u>
            </Link>
            {/* end::Link */}
          </div>
          {/* end::Wrapper */}

          {/* begin::Action */}
          <div className='d-grid mb-6'>
            <button
              style={{ height: '60px', color: 'white' }}
              type='submit'
              id='kt_sign_in_submit'
              className='btn btn-primary loginButtonLogin-screen'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && <span className='text-white fs-6 indicator-label'>Login</span>}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait....
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
        {/* end::Action */}
        {signUp && (
          <div className='text-white text-center fw-semibold fs-6'>
            {/* Not a Member yet?{' '}
          <Link to='/auth/registration' className='link-primary'>
            Sign up
          </Link> */}

            <div className='form-check-label forgot-pass fw-semibold fs-6'>
              Don't have an account?{' '}
              <Link to='/auth/registration' className='link-primary'>
                <span style={{ color: 'white' }}>
                  <u>Sign up</u>
                </span>
              </Link>
            </div>

            <div className='d-flex align-items-center my-10'>
              <div className='border-bottom border-gray-300 mw-50 w-100'></div>
              <span className='fw-semibold text-gray-300 fs-7 mx-2'>OR</span>
              <div className='border-bottom border-gray-300 mw-50 w-100'></div>
            </div>

            <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
              <GoogleLogin
                shape='rectangular'
                text='signin_with'
                width='328px'
                onSuccess={(credentialResponse) => {
                  const credential: any = credentialResponse?.credential
                  const userData: any = jwt_decode(credential)
                  const email = userData.email
                  const password = userData.sub
                  loginUser(email, password, credential)
                }}
                onError={() => {
                  console.log('Login Failed')
                }}
              />
            </GoogleOAuthProvider>

          </div>
        )}
      </form>

      <div className='mb-8' id="custom-toast-container"></div>


      {/* <div className='d-flex py-3 col-md-12' style={{ backgroundColor: '#F0E8E7', width:'100%', position:'absolute', alignItems:'center' }}>
        <img className='me-4' src={letterboxImage} width={'18px'} />
        <label>Your account has not been verified. Please verify your account</label>
      </div> */}

    </>
  )
}