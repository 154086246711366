//import liraries
import React, { useEffect, useState } from 'react'
import Pagination from 'react-bootstrap/Pagination'
import '../dashboard.css'
import imagePath from '../../../../constants/imagePath'
import { useAuth } from '../../auth/core/Auth'
import axios from 'axios'
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa'
import ShowError from '../../../../_metronic/partials/content/toasts/ShowError'
import * as XLSX from 'xlsx'
import CustomPagination from './CustomPagination'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import 'bootstrap-daterangepicker/daterangepicker.css'; // Import DateRangePicker CSS
import DateRangePicker from 'react-bootstrap-daterangepicker';
import RequisitionTaLeadFormPopup from '../../../../_metronic/partials/modals/JobFunnelsModels/RequisitionTaLeadFormPopup'

const API_URL = process.env.REACT_APP_API_URL
const GET_POSITIONS_URL = `${API_URL}/position/get-vacant-position-data`

// const ITEMS_PER_PAGE = 5; // Set the number of items you want to display per page

type Props = {
  taLead: any
  recruiter: any
}
// create a component
const VacantPositionsDashboard = ({ taLead, recruiter }: Props) => {
  const { currentUser, auth, logout } = useAuth()
  const [active, setActive] = useState('All')

  const [allPositions, setAllPositions] = useState<any>([])
  const [searchKey, setSearchKey] = useState('')
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1)
  const [currentPosition, setCurrentPosition] = useState<any>();
  const [showTALeadRequisitionForm, setShowTALeadRequisitionForm] = useState(false);
  const [params, setParams] = useState<any>({
    status: 'POSTED,REQUISITION_RAISED,REQUISITION_NOT_RAISED,HIRED,PENDING_APPROVAL',
    order_by: 'updated_at',
    direction: 'desc',
    page: 1,
    limit: 10,
    total: 0,
    search: '',
    start_date: '',
    end_date: ''

  })
  const [selectedRange, setSelectedRange] = useState('');

  const handleDateRangeChange = (event, picker) => {
    setSelectedRange(picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'));
    let startDate = picker.startDate.format('MM/DD/YYYY');
    let endDate = picker.endDate.format('MM/DD/YYYY');

    let data = { ...params };
    data.start_date = startDate;
    data.end_date = endDate;
    setParams(data);
  };

  const handleCancelDatePicker = () => {
    setSelectedRange('');
    let data = { ...params };
    data.start_date = '';
    data.end_date = '';
    setParams(data);
  }

  useEffect(() => {
    (async () => {
      await getCreatedPositions()
    })()
  }, [taLead, recruiter, params])

  // fetching list of all positions
  const getCreatedPositions = async () => {
    if (currentUser?.role === 'none') {
      return
    }

    let data = { ...params }
    // let url = GET_POSITIONS_URL
    if (recruiter || currentUser?.role === 'recruiter') {
      // url = `${url}?recruiter_ids=${recruiter?.user_id}`
      data.recruiter_ids = recruiter ? recruiter?.user_id : currentUser?.user_id
      // data.status = 'POSTED,REQUISITION_RAISED,HIRED'
    } else if (taLead || currentUser?.role === 'ta_lead') {
      // url = `${url}?ta_lead_id=${taLead?.user_id}`
      data.ta_lead_id = taLead ? taLead?.user_id : currentUser?.user_id
      // data.status = 'POSTED,REQUISITION_RAISED,HIRED'
    }
    const filteredParams = Object.fromEntries(
      Object.entries(data).filter(([_, value]) => value !== '' && value !== null && value !== undefined)
    )
    setLoading(true);
    await axios
      .get(GET_POSITIONS_URL, {
        params: filteredParams,
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        setLoading(false);
        let data = res?.data
        let pagination = data?.pagination
        if (pagination) {
          if (params.page !== pagination.page || params.limit !== pagination.limit || params.total !== pagination.total) {
            let paramsData = { ...params }
            paramsData.page = pagination?.page || 1
            paramsData.limit = pagination?.limit || 10
            paramsData.total = pagination?.total || 0
            setParams(paramsData)
          }
          setTotalPages(pagination?.totalPages || 0)
        }
        const positions = data?.data
        if (positions) {
          setAllPositions(positions)
          // changeInActiveIndex('All')
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response && error.response.status === 400) {
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          // Handle error message from the API
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  const changeInActiveIndex = (activeState: string) => {
    setActive(activeState)
    if (activeState === 'All') {
      let data = { ...params }
      data.status = 'POSTED,REQUISITION_RAISED,REQUISITION_NOT_RAISED,HIRED';
      setParams(data);
    }
    if (activeState === 'In Progress') {
      let data = { ...params }
      // data.status = 'POSTED,REQUISITION_RAISED';
      data.status = 'POSTED';
      setParams(data);
    }
    if (activeState === 'Unattended') {
      let data = { ...params }
      // data.status = 'REQUISITION_NOT_RAISED';
      data.status = 'REQUISITION_RAISED,REQUISITION_NOT_RAISED';
      setParams(data);
    }
    if (activeState === 'Closed') {
      let data = { ...params }
      data.status = 'HIRED';
      setParams(data);
    }
  }

  const [sortField, setSortField] = useState('')
  const [sortDirection, setSortDirection] = useState('asc')

  const sortByField = (field) => {
    const direction = field === sortField && sortDirection === 'asc' ? 'desc' : 'asc'
    // const sorted = [...filteredPositions].sort((a, b) => {
    //   const valueA = a[field]
    //   const valueB = b[field]
    //   if (valueA < valueB) return direction === 'asc' ? -1 : 1
    //   if (valueA > valueB) return direction === 'asc' ? 1 : -1
    //   return 0
    // })

    // setFilteredPositions(sorted)
    setSortField(field)
    setSortDirection(direction)

    let data = { ...params }
    data.order_by = field
    data.direction = direction === 'asc' ? 'asc' : 'desc'
    setParams(data)
  }

  const sortByRecruiterName = (field) => {
    const direction = field === sortField && sortDirection === 'asc' ? 'desc' : 'asc'
    // const sorted = [...filteredPositions].sort((a, b) => {
    //   const valueA = a.recruiterData.fullName
    //   const valueB = b.recruiterData.fullName
    //   if (valueA < valueB) return direction === 'asc' ? -1 : 1
    //   if (valueA > valueB) return direction === 'asc' ? 1 : -1
    //   return 0
    // })

    // setFilteredPositions(sorted)
    setSortField(field)
    setSortDirection(direction)

    let data = { ...params }
    data.order = field
    data.sort = direction === 'asc' ? 'asc' : 'desc'
    setParams(data)
  }

  const renderSortIcon = (field) => {
    if (field === sortField) {
      return sortDirection === 'asc' ? <FaSortUp /> : <FaSortDown />
    } else {
      return <FaSort />
    }
  }

  const handlePageChange = (page) => {
    // setCurrentPage(page);
    let data = { ...params }
    data.page = page
    setParams(data)
  }
  const handleItemsPerPageChange = (event) => {
    // setItemsPerPage(event.target.value);
    let data = { ...params }
    data.limit = event.target.value
    data.page = 1
    setParams(data)
  }

  const handleDownloadFile = () => {
    // Manually specify columns
    const columns = ['Recruiter', 'Position', 'Group', 'Remuneration Budget', 'Status']

    // Manually create data array
    const data = allPositions.map((row) => [
      `${row?.recruiterData?.name}` || '',
      row?.vacantPosition || '',
      row?.department || '',
      row?.maxTotalRemunerationPerMonth || '',
      row?.status || '',
    ])

    // Create worksheet
    const ws = XLSX.utils.aoa_to_sheet([columns, ...data])

    // Create workbook and add the worksheet
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'VacantPositions')

    // Download the Excel file
    XLSX.writeFile(wb, 'VacantPositionsReport.xlsx')
  }

  const handleSearchKeyChange = (value) => {
    setSearchKey(value)
    // if the value of searchKey is empty set the value of search as empty too
    if (!value) {
      // setSearch('')
      let data = { ...params }
      data.search = ''
      setParams(data)
    }
  }

  function handleKeyPress(event) {
    if (event.key === 'Enter') {
      handleSearch(event)
    }
  }

  const handleSearch = (e) => {
    e.preventDefault()
    let data = { ...params }
    if (searchKey && searchKey !== data.search) {
      // setSearch(searchKey)
      data.search = searchKey
      data.page = 1
      setParams(data)
    } else {
      // setSearch('')
      data.search = ''
      data.page = 1
      setParams(data)
    }

  }

  const getFormattedStatusValue = (value) => {
    if (value === 'POSTED') {
      return 'Posted'
    } else if (value === 'REQUISITION_NOT_RAISED') {
      return 'Requisition Not Raised'
    } else if (value === 'REQUISITION_RAISED') {
      return 'Requisition Raised'
    } else {
      return value
    }
  }

  const openRecruiterAssignmentForm = (position) => {
    setCurrentPosition(position);
    setShowTALeadRequisitionForm(true);
  }

  return (
    <div className='card-bg shadow-sm px-4 py-6 col-md-12'>
      <div>
        <div className='d-flex mb-4 align-items-center'>
          <img src={imagePath.ic_chair} alt='position' width={24} height={24} />
          <label className='fs-4 fw-bolder ms-2 me-2'>Positions</label>
          <div className='ms-auto d-flex align-items-center'>
            <div className='d-flex me-4' style={{ border: 'solid 1px #F57536', borderRadius: '4px', height: '32px' }}>
              <input
                style={{ width: '16rem' }}
                className='search-bar fs-6 ps-2 py-1'
                type='text'
                placeholder='Search'
                value={searchKey}
                // onChange={(event) => handleSearch(event.target.value)}
                onChange={(event) => handleSearchKeyChange(event.target.value)}
                onKeyPress={handleKeyPress}
              />
              <div
                className='d-flex align-items-center px-2'
                style={{
                  cursor: 'pointer',
                  color: '#FFFFFF',
                  backgroundColor: '#F57536',
                  height: '31px',
                  borderRadius: '0px 4px 4px 0px',
                }}
                onClick={(e) => handleSearch(e)}
              >
                <label className='fs-7 fw-bolder cursor-pointer' style={{ color: '#FFFFFF', marginLeft: '4px' }}>
                  Search{' '}
                </label>
                <img className='search-icon ms-2' src={imagePath.ic_search_white} />
              </div>
              {/* <img className='search-icon ' src={imagePath.ic_search_orange} /> */}
            </div>
            <DateRangePicker onApply={handleDateRangeChange}
              onCancel={handleCancelDatePicker}>
              <img src={imagePath.ic_calendar_orange} width={32} height={32} style={{ borderRadius: '4px', border: 'solid #F57536 1px', cursor: 'pointer' }} />
            </DateRangePicker>
            
            <img
              className='ms-4'
              src={imagePath.ic_download}
              width={32}
              height={32}
              style={{ borderRadius: '4px', border: 'solid #F57536 1px', padding: '5px', cursor: 'pointer' }}
              onClick={() => handleDownloadFile()}
              title='Download Report'
            />
            <img
              className='ms-2'
              style={{ cursor: 'pointer' }}
              src={imagePath.ic_refresh_orange}
              width={24}
              height={24}
              onClick={() => getCreatedPositions()}
            />
          </div>
        </div>
        <div className=' d-flex mb-7 gy-5 gx-xl-8'>
          <div
            style={{ cursor: 'pointer' }}
            className={`${active === 'All' ? 'active-position-tab' : 'inactive-position-tab  '}  fw-bolder mx-5 fs-4  `}
            onClick={() => changeInActiveIndex('All')}
          >
            {' '}
            All
          </div>
          <div
            style={{ cursor: 'pointer' }}
            className={`${active === 'In Progress' ? 'active-position-tab' : 'inactive-position-tab  '}  fw-bolder mx-5  fs-4  `}
            onClick={() => changeInActiveIndex('In Progress')}
          >
            {' '}
            In Progress
          </div>
          <div
            style={{ cursor: 'pointer' }}
            className={`${active === 'Unattended' ? 'active-position-tab' : 'inactive-position-tab  '}  fw-bolder mx-5  fs-4  `}
            onClick={() => changeInActiveIndex('Unattended')}
          >
            {' '}
            Unattended
          </div>

          <div
            style={{ cursor: 'pointer' }}
            className={`${active === 'Closed' ? 'active-position-tab' : 'inactive-position-tab  '}  fw-bolder  mx-5 fs-4  `}
            onClick={() => changeInActiveIndex('Closed')}
          >
            {' '}
            Closed
          </div>
        </div>

        <div style={{ position: 'relative' }}>
          {loading && (
            // Show loading icon if isLoading state is true
            <div
              className='position-absolute w-100 h-100 d-flex justify-content-center align-items-center'
              style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', top: 0, left: 0, zIndex: 999 }}
            >
              <FontAwesomeIcon icon={faSpinner} color='#F36523' spin size='4x' />
            </div>
          )}
          <div className='table-responsive' style={{ height: '26rem' }}>
            <table className='table align-middle gs-0 gy-4 table-borders'>
              <thead>
                <tr className='fw-bold graycolor bg-light'>
                  <th onClick={() => sortByField('title')} style={{ minWidth: '12rem' }} className='align-items-center ps-2' scope='col'>
                    <label className=' form-label fw-bolder fs-6 me-2'>Positions</label>
                    {renderSortIcon('title')}
                  </th>
                  <th onClick={() => sortByField('department')} style={{ minWidth: '12rem' }} className='align-items-center' scope='col'>
                    <label className=' form-label fw-bolder fs-6 me-2'>Groups</label>
                    {renderSortIcon('department')}
                  </th>
                  <th
                    onClick={() => sortByField('max_total_remuneration_per_month')}
                    style={{ minWidth: '14rem' }}
                    className='align-items-center'
                    scope='col'
                  >
                    <label className=' form-label fw-bolder fs-6 me-2'>Remuneration Budget</label>
                    {renderSortIcon('max_total_remuneration_per_month')}
                  </th>
                  <th
                    onClick={() => sortByRecruiterName('recruiterData')}
                    style={{ minWidth: '12rem' }}
                    className='align-items-center ps-2'
                    scope='col'
                  >
                    <label className=' form-label fw-bolder fs-6 me-2'>Recruiters</label>
                    {renderSortIcon('recruiterData')}
                  </th>
                  {currentUser?.role === 'ta_lead' && (<th
                    style={{ minWidth: '12rem' }}
                    className='align-items-center'
                    scope='col'
                  >
                    <label className=' form-label fw-bolder fs-6 me-2'>Actions</label>
                  </th>)}
                  <th onClick={() => sortByField('status')} style={{ minWidth: '13rem' }} className='align-items-center' scope='col'>
                    <label className=' form-label fw-bolder fs-6 me-2'>Status</label>
                    {renderSortIcon('status')}
                  </th>
                </tr>
              </thead>
              {/* </table>
                    <div style={{ height: '21rem', overflow: 'auto' }}>
                        <table className='table'> */}
              <tbody>
                {allPositions && allPositions.length > 0 ? (
                  allPositions.map((row, index) => {
                    let recruiter = row?.recruiterData
                    let dateFrom = row?.valid_from
                    dateFrom = dateFrom?.substring(0, 10)
                    let dateTo = row?.valid_till
                    dateTo = dateTo?.substring(0, 10)
                    let budget = row?.maxTotalRemunerationPerMonth
                    budget = parseInt(budget).toLocaleString()
                    const isEvenRow = index % 2 === 0
                    let status = getFormattedStatusValue(row?.status)
                    return (
                      <React.Fragment key={index}>
                        <tr style={{ backgroundColor: isEvenRow ? 'white' : '#FCFCFC', borderBottom: '1px solid #EDF2F9' }}>
                          <td className='ps-2'>
                            <h4 className=' form-label fs-6'>{row?.title}</h4>
                          </td>
                          <td className=' form-label fs-6'>{row?.department}</td>

                          <td className='fs-6'>
                            <h4 className=' form-label fs-7'>{`PKR ${budget}`}</h4>
                          </td>
                          <td className=' fs-6'>
                            {recruiter ? (<div className='d-flex align-items-center'>
                              <img
                                className='me-2'
                                src={recruiter?.profile_pic ? recruiter?.profile_pic : imagePath.userIcon}
                                width={'36px'}
                                height={'36px'}
                                style={{ borderRadius: '22px', objectFit: 'cover', border: 'solid #F57536 1px' }}
                              />
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <label className='form-label'>{`${recruiter?.name}`}</label>
                              </div>
                            </div>) : (<div className='text-center'>-</div>)}
                          </td>
                          {currentUser?.role === 'ta_lead' && (<td>
                            <button className='candi-btn2 btn text-white'
                              style={{ whiteSpace: 'nowrap', width: '12rem' }}
                              onClick={() => {
                                openRecruiterAssignmentForm(row)
                              }}>
                              {recruiter ? 'Reallocate' : 'Allocate Recruiter'}
                            </button>
                          </td>)}
                          <td className=' form-label fs-6'>{status}</td>
                        </tr>
                      </React.Fragment>
                    )
                  })
                ) : (
                  <label className='fs-6 form-label fs-bolder'>Positions Not available</label>
                )}
              </tbody>
            </table>
            {/* </div> */}
          </div>
        </div>

        <div className='d-flex col-md-12 align-items-center'>
          <div className='col-md-4'>
            <label>{`Showing ${allPositions ? allPositions.length : 0} of ${params.total} results.`}</label>
          </div>
          <div className='mx-auto col-md-4' style={{ textAlign: 'center' }}>
            <CustomPagination
              pageValue={params.page}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </div>

          <div className='col-md-4 '>
            <div className='ms-auto d-flex flex-end align-items-center'>
              <select
                className='me-2 pagination-input'
                style={{ backgroundColor: '#FFFFFF', borderRadius: '2px' }}
                id='selectValues'
                // value={itemsPerPage}
                value={params.limit}
                onChange={handleItemsPerPageChange}
              >
                <option value='10'>10</option>
                <option value='25'>25</option>
                <option value='50'>50</option>
              </select>

              <div className='me-2'>
                <label>Items per page</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showTALeadRequisitionForm &&
        <RequisitionTaLeadFormPopup
          handleClose={() => {
            setShowTALeadRequisitionForm(false);
          }}
          position={currentPosition}
        />
      }
      {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}
    </div>
  )
}

//make this component available to the app
export default VacantPositionsDashboard
