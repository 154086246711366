//import liraries
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from '../../auth/core/Auth';
import ShowError from '../../../../_metronic/partials/content/toasts/ShowError';
import imagePath from '../../../../constants/imagePath';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import CustomPagination from '../../dashboards/components/CustomPagination';
import { getFormattedDate } from '../../../../constants/CommonFunctions';


const API_URL = process.env.REACT_APP_API_URL
const GET_HIRING_PROCEDURE_REPORT_URL = `${API_URL}/analytics/recruitment-tracking-report`


// create a component
const TrackingHiringProcedureTable = () => {

    const currentYear = new Date().getFullYear();
    const { currentUser, auth, logout } = useAuth();
    const [year, setYear] = useState(currentYear);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [reportData, setReportData] = useState<any>();
    const [totalPages, setTotalPages] = useState(1)
    const [params, setParams] = useState<any>({
        // order: 'updated_at',
        // sort: 'DESC',
        page: 1,
        limit: 10,
        total: 0,
        // age: '',
        // bankingExp: '',
        // totalExp: '',
        // universities: '',
    })

    useEffect(() => {
        (async () => {
            await getReportData();
        })()
    }, [params]);

    const getReportData = async () => {
        // let params = {};
        if (year) {
            params['year'] = year;
        }
        setLoading(true);
        await axios.get(GET_HIRING_PROCEDURE_REPORT_URL, {
            params,
            headers: {
                Authorization: `Bearer ${auth?.token}`,
                'Content-Type': 'application/json',
            },
        }).then((res) => {
            setLoading(false);
            let data = res?.data;
            setReportData(data?.data);


        }).catch((error) => {
            setLoading(false);
            if (error.response && error.response.status === 400) {
                // showToast(error.response.data.message)
                setMessage(error.response.data.message);
                setShowErrorMessage(true);
            } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
                logout();
            } else if (error.response && error.response.data && error.response.data.error) {
                if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                    logout();
                } else {
                    setMessage(error.response.data.error.message);
                    setShowErrorMessage(true);
                }
            } else {
                setMessage(error.message);
                setShowErrorMessage(true);
            }
        });
    }

    const handleChangeYear = (e) => {
        const value = e.target.value;
        if (!isNaN(value) && parseInt(value) <= currentYear) {
            setYear(value);
        }
    }

    const handleDownloadFile = (url) => {
        if (!url) {
            setMessage('Report URL not available!');
            setShowErrorMessage(true);
            return;
        }
        fetch(url)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.blob();
            })
            .then((blob) => {
                // Create a link element, use it to download the blob, and remove it
                const blobUrl = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = blobUrl;
                link.download = url.split('/').pop(); // Extracts file name from URL
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(blobUrl);
            })
            .catch((error) => {
                console.error('Error downloading the file:', error);
            });
    };

    const handlePageChange = (page) => {
        let data = { ...params }
        data.page = page
        setParams(data)
    }
    const handleItemsPerPageChange = (event) => {
        let data = { ...params }
        data.limit = event.target.value
        setParams(data)
    }

    return (
        <div>
            <div style={{ position: 'relative' }}>
                {loading && (
                    // Show loading icon if isLoading state is true
                    <div
                        className='position-absolute w-100 h-100 d-flex justify-content-center align-items-center'
                        style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', top: 0, left: 0, zIndex: 999 }}
                    >
                        <FontAwesomeIcon icon={faSpinner} color='#F36523' spin size='4x' />
                    </div>
                )}

                <div className='card-bg shadow-sm mb-10' >
                    <div className='d-flex align-items-center px-6 py-8'>
                        <label className='fs-3 fw-bolder me-4' style={{ maxWidth: '18rem' }}>Recruitment Tracking Report For Hiring Procedure</label>
                        <div className='d-flex align-items-center ms-auto'>
                            {/* year view */}
                            <div className='d-flex align-items-center mx-4'>

                                <input
                                    className='py-2 px-2 fs-5 fw-semibold'
                                    style={{ border: 'solid 1px #F36523', borderRadius: '6px', color: '#F36523', width: '8rem' }}
                                    type='number'
                                    inputMode='numeric'
                                    placeholder='2024'
                                    value={year}
                                    max={currentYear}
                                    min={2023}
                                    onChange={(event) => handleChangeYear(event)}
                                />
                            </div>
                            {/* download report view */}
                            <div className='d-flex py-2 px-2' style={{ border: 'solid 1px #F36523', borderRadius: '6px' }}>
                                <div className='d-flex align-items-center' style={{ cursor: 'pointer' }} onClick={() => handleDownloadFile('')}>
                                    <label className='fs-5 fw-semibold pe-4 cursor-pointer' style={{ color: '#F36523', whiteSpace: 'nowrap' }}>Download Report</label>
                                    <img className='cursor-pointer' src={imagePath.ic_download} width={24} height={24} />
                                </div>
                            </div>

                            {/* refresh view */}
                            <div>
                                <img className='cursor-pointer ' src={imagePath.ic_refresh_orange} width={34} height={34}
                                    onClick={() => {
                                        getReportData();
                                    }} />
                            </div>
                        </div>
                    </div>

                    {/* table view */}

                    <div>
                        <div className='table-responsive'>
                            <div className="table-wrapper" style={{ overflowX: 'auto', position: 'relative' }}>
                                <div className='scrollable-table-container'>
                                    <table className='table align-middle table-borders'>
                                        <thead>
                                            <tr className='fw-bold graycolor bg-light align-items-center'>
                                                <th className='ps-6 fw-bolder my-auto sticky-header' style={{
                                                    color: '#475467',
                                                    whiteSpace: 'nowrap',
                                                    verticalAlign: 'middle',
                                                    minWidth: '16rem',
                                                    position: 'sticky',
                                                    left: 0,
                                                    background: '#F5F8FA',
                                                    zIndex: 2
                                                }}>Group</th>

                                                <th className='ps-6 fw-bolder sticky-header' style={{ color: '#475467', border: '1px solid #EDF2F9', whiteSpace: 'nowrap', position: 'sticky', left: 0, background: '#F5F8FA', zIndex: 1 }}>Division</th>
                                                <th className='ps-6 fw-bolder sticky-header' style={{ color: '#475467', border: '1px solid #EDF2F9', whiteSpace: 'nowrap', position: 'sticky', left: 0, background: '#F5F8FA', zIndex: 1 }}>Department</th>
                                                <th className='ps-6 fw-bolder sticky-header' style={{ color: '#475467', border: '1px solid #EDF2F9', whiteSpace: 'nowrap', position: 'sticky', left: 0, background: '#F5F8FA', zIndex: 1 }}>Section</th>
                                                <th className='ps-6 fw-bolder sticky-header' style={{ color: '#475467', border: '1px solid #EDF2F9', whiteSpace: 'nowrap', position: 'sticky', left: 0, background: '#F5F8FA', zIndex: 1 }}>Location</th>
                                                <th className='ps-6 fw-bolder sticky-header' style={{ color: '#475467', border: '1px solid #EDF2F9', whiteSpace: 'nowrap', position: 'sticky', left: 0, background: '#F5F8FA', zIndex: 1 }}>Position Title</th>
                                                <th className='ps-6 fw-bolder sticky-header' style={{ color: '#475467', border: '1px solid #EDF2F9', whiteSpace: 'nowrap', position: 'sticky', left: 0, background: '#F5F8FA', zIndex: 1 }}>Grade</th>
                                                <th className='ps-6 fw-bolder sticky-header' style={{ color: '#475467', border: '1px solid #EDF2F9', whiteSpace: 'nowrap', position: 'sticky', left: 0, background: '#F5F8FA', zIndex: 1 }}>Candidate CNIC</th>
                                                <th className='ps-6 fw-bolder sticky-header' style={{ color: '#475467', border: '1px solid #EDF2F9', whiteSpace: 'nowrap', position: 'sticky', left: 0, background: '#F5F8FA', zIndex: 1 }}>Candidate Name</th>
                                                <th className='ps-6 fw-bolder sticky-header' style={{ color: '#475467', border: '1px solid #EDF2F9', whiteSpace: 'nowrap', position: 'sticky', left: 0, background: '#F5F8FA', zIndex: 1 }}>Hiring Manager Name (WITH EIN)</th>
                                                <th className='ps-6 fw-bolder sticky-header' style={{ color: '#475467', border: '1px solid #EDF2F9', whiteSpace: 'nowrap', position: 'sticky', left: 0, background: '#F5F8FA', zIndex: 1 }}>Recruiter Name (WITH EIN)</th>
                                                <th className='ps-6 fw-bolder sticky-header' style={{ color: '#475467', border: '1px solid #EDF2F9', whiteSpace: 'nowrap', position: 'sticky', left: 0, background: '#F5F8FA', zIndex: 1 }}>Source</th>
                                                <th className='ps-6 fw-bolder sticky-header' style={{ color: '#475467', border: '1px solid #EDF2F9', whiteSpace: 'nowrap', position: 'sticky', left: 0, background: '#F5F8FA', zIndex: 1 }}>Ad Opening Date</th>
                                                <th className='ps-6 fw-bolder sticky-header' style={{ color: '#475467', border: '1px solid #EDF2F9', whiteSpace: 'nowrap', position: 'sticky', left: 0, background: '#F5F8FA', zIndex: 1 }}>Ad Closing Date</th>
                                                <th className='ps-6 fw-bolder sticky-header' style={{ color: '#475467', border: '1px solid #EDF2F9', whiteSpace: 'nowrap', position: 'sticky', left: 0, background: '#F5F8FA', zIndex: 1 }}>Test Date</th>
                                                <th className='ps-6 fw-bolder sticky-header' style={{ color: '#475467', border: '1px solid #EDF2F9', whiteSpace: 'nowrap', position: 'sticky', left: 0, background: '#F5F8FA', zIndex: 1 }}>Interview Date</th>
                                                <th className='ps-6 fw-bolder sticky-header' style={{ color: '#475467', border: '1px solid #EDF2F9', whiteSpace: 'nowrap', position: 'sticky', left: 0, background: '#F5F8FA', zIndex: 1 }}>Selection Phase</th>
                                                <th className='ps-6 fw-bolder sticky-header' style={{ color: '#475467', border: '1px solid #EDF2F9', whiteSpace: 'nowrap', position: 'sticky', left: 0, background: '#F5F8FA', zIndex: 1 }}>Decision</th>
                                                <th className='ps-6 fw-bolder sticky-header' style={{ color: '#475467', border: '1px solid #EDF2F9', whiteSpace: 'nowrap', position: 'sticky', left: 0, background: '#F5F8FA', zIndex: 1 }}>Decision Date</th>
                                                <th className='ps-6 fw-bolder sticky-header' style={{ color: '#475467', border: '1px solid #EDF2F9', whiteSpace: 'nowrap', position: 'sticky', left: 0, background: '#F5F8FA', zIndex: 1 }}>Time to Hire (TAT)</th>
                                                <th className='ps-6 fw-bolder sticky-header' style={{ color: '#475467', border: '1px solid #EDF2F9', whiteSpace: 'nowrap', position: 'sticky', left: 0, background: '#F5F8FA', zIndex: 1 }}>Hiring Cost</th>
                                                <th className='ps-6 fw-bolder sticky-header' style={{ color: '#475467', border: '1px solid #EDF2F9', whiteSpace: 'nowrap', position: 'sticky', left: 0, background: '#F5F8FA', zIndex: 1 }}>Vacant Position Status</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(reportData && reportData.length > 0) ? reportData.map((report, index) => {
                                                let applications = report.applications;
                                                // console.log('reportData: ', applications)
                                                if (applications && applications.length > 0) {
                                                    return applications.map((data, appIndex) => {
                                                        console.log('reportData:Application ', data)
                                                        const isEvenRow = appIndex % 2 === 0
                                                        return (
                                                            <tr key={appIndex} style={{ backgroundColor: isEvenRow ? 'white' : '#FCFCFC', borderBottom: '1px solid #EDF2F9' }} className='mx-2'>
                                                                <td className='ps-6 fw-bolder' style={{ color: '#475467', border: '1px solid #EDF2F9', position: 'sticky', left: 0, zIndex: 1, background: isEvenRow ? 'white' : '#FCFCFC' }} >{report?.groupName}</td>
                                                                <td className='ps-6' style={{ border: '1px solid #EDF2F9' }} >{report?.functionalArea}</td>
                                                                <td className='ps-6' style={{ border: '1px solid #EDF2F9' }} >{report?.subFunctionalArea}</td>
                                                                <td className='ps-6' style={{ border: '1px solid #EDF2F9' }} >{report?.section}</td>
                                                                <td className='ps-6' style={{ border: '1px solid #EDF2F9' }} >{report?.location}</td>
                                                                <td className='ps-6' style={{ border: '1px solid #EDF2F9' }} >{report?.title || ''}</td>
                                                                <td className='ps-6' style={{ border: '1px solid #EDF2F9' }} >{report?.grade || ''}</td>
                                                                <td className='ps-6' style={{ border: '1px solid #EDF2F9' }} >{data?.cnic || ''}</td>
                                                                <td className='ps-6' style={{ border: '1px solid #EDF2F9' }} >{data?.candidateName || ''}</td>
                                                                <td className='ps-6' style={{ border: '1px solid #EDF2F9' }} >{report?.taLeadName}</td>
                                                                <td className='ps-6' style={{ border: '1px solid #EDF2F9' }} >{report?.recruiterName || ''}</td>
                                                                <td className='ps-6' style={{ border: '1px solid #EDF2F9' }} >{report?.source || ''}</td>
                                                                <td className='ps-6' style={{ border: '1px solid #EDF2F9' }} >{getFormattedDate(report?.valid_from) || ''}</td>
                                                                <td className='ps-6' style={{ border: '1px solid #EDF2F9' }} >{getFormattedDate(report?.valid_till) || ''}</td>
                                                                <td className='ps-6' style={{ border: '1px solid #EDF2F9' }} >{getFormattedDate(data?.testDate) || ''}</td>
                                                                <td className='ps-6' style={{ border: '1px solid #EDF2F9' }} >{getFormattedDate(data?.interviewDate) || ''}</td>
                                                                <td className='ps-6' style={{ border: '1px solid #EDF2F9' }} >{data?.jobapp_stage || ''}</td>
                                                                <td className='ps-6' style={{ border: '1px solid #EDF2F9' }} >{data?.jobapp_status}</td>
                                                                <td className='ps-6' style={{ border: '1px solid #EDF2F9' }} >{data?.jobapp_stage === 'hired' ? getFormattedDate(data?.updated_at):'-'}</td>
                                                                <td className='ps-6' style={{ border: '1px solid #EDF2F9' }} >{'-'}</td>
                                                                <td className='ps-6' style={{ border: '1px solid #EDF2F9' }} >{'-'}</td>
                                                                <td className='ps-6' style={{ border: '1px solid #EDF2F9' }} >{'-'}</td>
                                                            </tr>
                                                        );

                                                    })
                                                }


                                            }) : (<div></div>)}
                                        </tbody>

                                        {/* <tbody>
                                            {reportData && reportData.length > 0 ? (
                                                reportData.flatMap((report, index) => {
                                                    let applications = report.applications;
                                                    return applications && applications.length > 0
                                                        ? applications.map((data, appIndex) => {
                                                            const isEvenRow = appIndex % 2 === 0;
                                                            return (
                                                                <tr
                                                                    key={appIndex}
                                                                    style={{
                                                                        backgroundColor: isEvenRow ? 'white' : '#FCFCFC',
                                                                        borderBottom: '1px solid #EDF2F9'
                                                                    }}
                                                                    className='mx-2'
                                                                >
                                                                    <td
                                                                        className='ps-6 fw-bolder'
                                                                        style={{
                                                                            color: '#475467',
                                                                            border: '1px solid #EDF2F9',
                                                                            position: 'sticky',
                                                                            left: 0,
                                                                            zIndex: 1,
                                                                            background: isEvenRow ? 'white' : '#FCFCFC'
                                                                        }}
                                                                    >
                                                                        {'Missing value'}
                                                                    </td>
                                                                    <td className='ps-6' style={{ border: '1px solid #EDF2F9' }}>
                                                                        {data?.cnic || 'Missing value'}
                                                                    </td>
                                                                    <td className='ps-6' style={{ border: '1px solid #EDF2F9' }}>
                                                                        {report?.title || 'Missing value'}
                                                                    </td>
                                                                    <td className='ps-6' style={{ border: '1px solid #EDF2F9' }}>
                                                                        {report?.grade || 'Missing value'}
                                                                    </td>
                                                                    <td className='ps-6' style={{ border: '1px solid #EDF2F9' }}>
                                                                        {report?.recruiterName || 'Missing value'}
                                                                    </td>
                                                                    <td className='ps-6' style={{ border: '1px solid #EDF2F9' }}>
                                                                        {report?.source || 'Missing value'}
                                                                    </td>
                                                                    <td className='ps-6' style={{ border: '1px solid #EDF2F9' }}>
                                                                        {getFormattedDate(report?.valid_from) || 'Missing value'}
                                                                    </td>
                                                                    <td className='ps-6' style={{ border: '1px solid #EDF2F9' }}>
                                                                        {getFormattedDate(report?.valid_till) || 'Missing value'}
                                                                    </td>
                                                                    <td className='ps-6' style={{ border: '1px solid #EDF2F9' }}>
                                                                        {getFormattedDate(data?.testDate) || 'Missing value'}
                                                                    </td>
                                                                    <td className='ps-6' style={{ border: '1px solid #EDF2F9' }}>
                                                                        {getFormattedDate(data?.interviewDate) || 'Missing value'}
                                                                    </td>
                                                                    <td className='ps-6' style={{ border: '1px solid #EDF2F9' }}>
                                                                        {data?.jobapp_stage || 'Missing value'}
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                        : null;
                                                })
                                            ) : (
                                                <tr>
                                                    <td >No data available</td>
                                                </tr>
                                            )}
                                        </tbody> */}
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* pagination view */}
                    <div className='px-6 py-8 d-flex col-md-12 align-items-center'>
                        <div className='col-md-4'>
                            <label>{`Showing ${reportData ? reportData.length : 0} of ${params.total} results.`}</label>
                        </div>
                        <div className='mx-auto col-md-4' style={{ textAlign: 'center' }}>
                            <CustomPagination
                                // totalPages={Math.ceil(allUsers.length / itemsPerPage)}
                                // pageValue={currentPage}
                                pageValue={params.page}
                                totalPages={totalPages}
                                onPageChange={handlePageChange}
                            />
                        </div>

                        <div className='col-md-4 '>
                            <div className='ms-auto d-flex flex-end align-items-center'>
                                <select
                                    className='me-2 pagination-input'
                                    style={{ backgroundColor: '#FFFFFF', borderRadius: '2px' }}
                                    id='selectValues'
                                    // value={itemsPerPage}
                                    value={params.limit}
                                    onChange={handleItemsPerPageChange}
                                >
                                    <option value='10'>10</option>
                                    <option value='25'>25</option>
                                    <option value='50'>50</option>
                                </select>

                                <div className='me-2'>
                                    <label>Items per page</label>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {
                showErrorMessage && (
                    <ShowError
                        handleClose={() => setShowErrorMessage(false)}
                        message={message}
                    />
                )
            }
        </div>
    );
};

//make this component available to the app
export default TrackingHiringProcedureTable;
