//import liraries
import React, { useState, useEffect, useRef } from 'react'
import { Modal } from 'react-bootstrap'
import { createPortal } from 'react-dom'
import { useAuth } from '../../../../app/modules/auth/core/Auth'
import axios from 'axios'
import LoaderView from './LoaderView'
import ShowError from '../../content/toasts/ShowError'
import imagePath from '../../../../constants/imagePath'
const API_URL = process.env.REACT_APP_API_URL
const GET_GRADES_URL = `${API_URL}/configuration/get-job-grade`
const GET_ALL_USERS_URL = `${API_URL}/auth/user-list`
const SAVE_APPROVING_AUTHORITIES_URL = `${API_URL}/configuration/add-approving-authorities`;

type Props = {
    show: boolean
    formType: string
    configurationId: string
    currentIndex: number
    allAuthorities: any
    handleClose: () => void
    updateAuthoritiesList: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

// create a component
const AddUpdateApprovingAuthorityPopup = ({ show, formType, configurationId, currentIndex, allAuthorities, handleClose, updateAuthoritiesList }: Props) => {
    const { currentUser, auth, logout } = useAuth();
    const [loading, setLoading] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [message, setMessage] = useState('');
    const [label, setLabel] = useState('');
    const [allGrades, setAllGrades] = useState<any>();
    const [authorityType, setAuthorityType] = useState('');
    const [grade, setGrade] = useState('');
    const [selectedGrades, setSelectedGrades] = useState<any>([])
    const [labels, setLabels] = useState<any>(['Up to OG-I', 'AVP-I to VP', 'SVP-I & above', 'Daily Wagers & Outsourced Staff', 'Panel Approval upto VP & Re-advertisement', 'All cases with 25% above raise'])
    const [titles, setTitles] = useState<any>(['Group Head P&OE', 'Chief Financial Officer', 'Concerned Group Head', 'Group Chief C&IB', 'President/CEO', 'Chief of Staff & Strategy', 'CFO', 'Concerned Approving Authorities of that Grade']);
    const [authorities, setAuthorities] = useState<any>([{
        title: '',
        user: null
    }])
    const [employeeQuery, setEmployeeQuery] = useState('')
    const [employeeResults, setEmployeeResults] = useState<any>([])
    const [employees, setEmployees] = useState<any>([])
    const [activeDropdownIndex, setActiveDropdownIndex] = useState<number | null>(null);
    const employeeRefs = useRef<any[]>([]);
    const [allGradeAuthorities, setAllGradeAuthorities] = useState<any>([]);

    useEffect(() => {
        getGrades();
        document.addEventListener('click', handleDocumentClick);
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, []);

    useEffect(() => {
        (() => {
            if (allAuthorities && allAuthorities.length > 0) {
                if (formType === 'update') {
                    if (currentIndex < allAuthorities.length) {
                        let data = allAuthorities[currentIndex]
                        setLabel(data?.label)
                        setSelectedGrades(data?.grades);
                        setAuthorityType(data?.type)
                        setAuthorities(data?.authorities);
                    }
                } else {

                }
                setAllGradeAuthorities(allAuthorities);
            }
        })()
    }, [allAuthorities])

    const handleDocumentClick = (event: MouseEvent) => {
        if (activeDropdownIndex !== null && employeeRefs.current[activeDropdownIndex] && !employeeRefs.current[activeDropdownIndex].contains(event.target as Node)) {
            setEmployeeResults([]);
            setActiveDropdownIndex(null);
        }
    };

    useEffect(() => {
        (() => {
            getGrades();
            getAllUsers();
        })()
    }, []);

    const getGrades = async () => {
        await axios
            .get(GET_GRADES_URL, {
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                    'Content-Type': 'application/json',
                },
            })
            .then((res) => {
                let data = res?.data?.job_grade
                if (data) {
                    data = JSON.parse(data)
                    console.log('allGrades: ', data);
                    if (data) {
                        setAllGrades(Object.values(data))
                    }
                }
            })
            .catch((error) => {
                handleErrorResponse(error);
            })
    }
    // get all users
    const getAllUsers = async () => {
        await axios.get(GET_ALL_USERS_URL, {
            headers: {
                Authorization: `Bearer ${auth?.token}`,
                'Content-Type': 'application/json',
            },
        }).then((res) => {
            setEmployees(res.data?.data)
        }).catch((error) => {
            handleErrorResponse(error);
        });
    }

    const handleErrorResponse = (error: any) => {
        if (error.response && error.response.status === 400) {
            setMessage(error.response.data.message)
            setShowErrorMessage(true)
        } else if (
            error.response &&
            error.response.status === 401 &&
            error.response.data &&
            error.response.data.name === 'TokenExpiredError'
        ) {
            logout()
        } else if (error.response && error.response.data && error.response.data.error) {
            if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                logout()
            } else {
                setMessage(error.response.data.error.message)
                setShowErrorMessage(true)
            }
        } else {
            setMessage(error.message)
            setShowErrorMessage(true)
        }
    }


    const handleChangeLabel = (e) => {
        setLabel(e.target.value)
    }

    const handleAddGrade = (event) => {
        const value = event.target.value
        if (value && !selectedGrades.includes(value)) {
            setSelectedGrades([...selectedGrades, value])
            setGrade(value)
        } else {
            setMessage('Grade already exists in the list.')
            setShowErrorMessage(true)
        }
    }

    const handleChangeType = (e) => {
        setAuthorityType(e.target.value)
    }

    const handleRemoveGrade = (index) => {
        const updatedList = [...selectedGrades];
        updatedList.splice(index, 1);
        setSelectedGrades(updatedList);
    }

    const handleSelectApprovingAuthority = (event, index) => {
        const value = event.target.value;
        // Check if the title is already selected
        const isTitleAlreadySelected = authorities.some((authority, i) => authority.title === value && i !== index);

        if (isTitleAlreadySelected) {
            // Optionally, show an error message or handle the error as needed
            setMessage('This title is already selected.');
            setShowErrorMessage(true);
        } else {
            let data = [...authorities];
            data[index]['title'] = value;
            setAuthorities(data);
        }
    }

    // filter the list of Group Heads
    const handleEmployeeChange = (event, index) => {
        const query = event.target.value;
        setEmployeeQuery(query);
        if (query === null || query === undefined || query === '') {
            // let data = [...authorities];
            // data[index]['user'] = null;
            // setAuthorities(data);
            setActiveDropdownIndex(null);

        } else {
            setActiveDropdownIndex(index);
        }
        let filteredResults = employees.filter((item) => {
            let fullNameEmployeeId = `${item['first_name']} ${item['last_name']} ${item['employee_id']}`
            return fullNameEmployeeId.toLowerCase().includes(query.toLowerCase())
        })
        setEmployeeResults(filteredResults.length > 0 ? filteredResults : employees)
    }

    const handleEmployeeClick = (item, index) => () => {
        // Check if the user is already selected in any authority
        const isUserAlreadySelected = authorities.some((authority, i) => authority.user?.user_id === item.user_id && i !== index);

        if (isUserAlreadySelected) {
            // Optionally, show an error message or handle the error as needed
            setMessage('This user is already selected as an authority.');
            setShowErrorMessage(true);
            setActiveDropdownIndex(null);
        } else {
            let query = `${item['first_name']} ${item['last_name']} ${item['employee_id']}`
            setEmployeeQuery(query)
            setEmployeeResults([])
            setActiveDropdownIndex(null);

            let data = [...authorities];
            data[index]['user'] = item;
            setAuthorities(data);
        }

    }

    const handleRemoveAuthority = (index) => {
        if (authorities && authorities.length > 1) {

            const updatedList = [...authorities];
            updatedList.splice(index, 1);
            setAuthorities(updatedList);

        } else {
            setMessage('Can not remove all entries');
            setShowErrorMessage(true);
        }
    }

    const handleAddApprovingAuthority = () => {
        let allAuthorities = [...authorities];
        if (allAuthorities.length < 3) {
            let data = {
                title: '',
                user: null
            }
            allAuthorities.push(data);
            setAuthorities(allAuthorities);
        } else {
            setMessage('Can not add more than 3 approving authorities');
            setShowErrorMessage(true);
            return;
        }
    }

    const handleValidation = () => {
        return authorities.every((item) => {
            if (item.title === 'Concerned Group Head' || item.title === 'Concerned Approving Authorities of that Grade') {
                return true;
            }
            return item.title && item.user;
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!label) {
            setMessage('Label can not be empty!');
            setShowErrorMessage(true);
            return;
        }
        if (selectedGrades === null || selectedGrades === undefined || selectedGrades.length <= 0) {
            setMessage('List of grades can not be empty!');
            setShowErrorMessage(true);
            return;
        }
        if (!authorityType) {
            setMessage('Please select Authority Type first and try again!');
            setShowErrorMessage(true);
            return;
        }
        if (authorities === null || authorities === undefined || authorities.length <= 0) {
            setMessage('Approving authorities can not be empty!');
            setShowErrorMessage(true);
            return;
        }
        if (!handleValidation()) {
            setMessage('Title and Appoint Role of Approving Authorities can not be empty!');
            setShowErrorMessage(true);
            return;
        }

        let authorityData = {
            label: label,
            date: new Date(),
            grades: selectedGrades,
            type: authorityType,
            authorities: authorities,
            active: false
        }
        let data: any = [];
        if (formType === 'create') {
            data = [...allGradeAuthorities];
            data.push(authorityData);

        } else {
            data = [...allGradeAuthorities];
            authorityData.active = data[currentIndex]['active'] ? data[currentIndex]['active'] : false;
            data[currentIndex] = authorityData;
            setAllGradeAuthorities(data);
        }

        let transformedData = {};
        data.forEach((item, index) => {
            transformedData[index] = item;
        });

        let postData = {
            configuration_id: configurationId,
            // approving_authorities: JSON.stringify(transformedData)
            approving_authorities: transformedData
        }
        setLoading(true);
        await axios.post(SAVE_APPROVING_AUTHORITIES_URL, postData, {
            headers: {
                Authorization: `Bearer ${auth?.token}`,
                'Content-Type': 'application/json',
            },
        }).then((res) => {
            setLoading(false)
            // fetch updated data
            // getConfigurations();
            updateAuthoritiesList();

        }).catch((error) => {
            setLoading(false)
            handleErrorResponse(error);
        });

    }

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-700px'
            show={show}
            onHide={handleClose}
            backdrop={true}
            backdropClassName='modal-backdrop backdrop-blur'
        >
            <div className=' no-header-model123 pb-10'>
                <div className='modal-header no-header-model123'>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                        <label className='form-label fs-5 fw-bolder' style={{ color: '#F36523', marginLeft: '8px' }}>
                            {' '}
                            {formType === 'create' ? 'Add Approving Authority' : 'Update Approving Authority'}
                        </label>
                    </div>

                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                        <img alt='' src={imagePath.ic_close}></img>
                    </div>
                </div>
                <div className='mx-10'>
                    <label className='form-label fs-5 fw-bolder'>Label</label>
                    {/* <input
                        className='form-control'
                        type='text'
                        value={label}
                        placeholder='Type here'
                        onChange={handleChangeLabel}
                    /> */}
                    <select
                        name='status'
                        data-control='select2'
                        data-hide-search='true'
                        className='form-select '
                        defaultValue=''
                        placeholder='Select an option'
                        value={label}
                        required
                        onChange={(event) => handleChangeLabel(event)}

                    >
                        <option value=''>Select label</option>
                        {labels ? labels.map((data) => (
                            <option key={data} value={data}>
                                {data}
                            </option>
                        )) : (<div></div>)}
                    </select>
                </div>
                <div className='mx-10 mt-10'>
                    <label className='form-label fs-5 fw-bolder'>Select Grades</label>
                    <div className='mb-4'>
                        <select
                            name='status'
                            data-control='select2'
                            data-hide-search='true'
                            className='form-select '
                            defaultValue=''
                            placeholder='Select an option'
                            value={grade}
                            required
                            onChange={(event) => handleAddGrade(event)}

                        >
                            <option value=''>Select grades</option>
                            {allGrades ? allGrades.map((grade) => (
                                <option key={grade} value={grade}>
                                    {grade}
                                </option>
                            )) : (<div></div>)}
                        </select>
                    </div>
                    <div style={{ overflow: 'auto' }}>
                        {(selectedGrades && selectedGrades.length > 0) ? selectedGrades.map((item, index) => (
                            <div key={index} className="badge orange-tag me-4 mb-2" style={{ position: 'relative', zIndex: '1' }}>
                                {item}
                                <img className='ms-4' style={{ cursor: 'pointer' }} src={imagePath.ic_cross_orange}
                                    onClick={() => {
                                        handleRemoveGrade(index)
                                    }}
                                />
                            </div>
                        )) : (<div></div>)}
                    </div>
                </div>
                <div className='mx-10 mt-10'>
                    <label className='form-label fs-5 fw-bolder'>Approving Authority Type</label>
                    <select
                        name='status'
                        data-control='select2'
                        data-hide-search='true'
                        className='form-select '
                        defaultValue=''
                        placeholder='Select an option'
                        value={authorityType}
                        required
                        onChange={(event) => handleChangeType(event)}

                    >
                        <option value=''>Select label</option>
                        <option value='Job'>Job</option>
                        <option value='Panel'>Panel</option>
                        <option value='PI'>PI</option>
                    </select>
                </div>
                {(authorities && authorities.length > 0) ? authorities.map((data, index) => {

                    return (
                        <div key={index} className=' mx-10 mt-6 py-4 d-flex flex-column' style={{ backgroundColor: '#F9FAFB', borderRadius: '10px' }}>
                            <div >
                                <div className='px-2 py-1 me-8 cursor-pointer' style={{ float: 'right', backgroundColor: '#F36523', borderRadius: '16px', color: 'white', display: 'flex', alignItems: 'center' }}
                                    onClick={() => handleRemoveAuthority(index)}>
                                    Delete
                                    <i className="bi bi-x" style={{ marginLeft: '4px', color: 'white', fontSize: '18px' }}></i>
                                </div>
                            </div>
                            <div className=' d-flex '>
                                <div className='col-md-6'>
                                    <div className='pe-4'>
                                        <label className='form-label fs-5 fw-bolder'>{`Select Approving Authority ${index + 1}`}</label>
                                        <div >
                                            <select
                                                name='status'
                                                data-control='select2'
                                                data-hide-search='true'
                                                className='form-select '
                                                defaultValue=''
                                                placeholder='Select an option'
                                                value={data?.title}
                                                required
                                                onChange={(event) => handleSelectApprovingAuthority(event, index)}

                                            >
                                                <option value=''>Select Approving Authority</option>
                                                {titles.map((title) => (
                                                    <option key={title} value={title}>
                                                        {title}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        {data?.title && (
                                            <div className="mt-4 badge orange-tag " >
                                                {data?.title}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {(data?.title !== 'Concerned Group Head' && data?.title !== 'Concerned Approving Authorities of that Grade') && (
                                    <div className='col-md-6'>
                                        <div >
                                            <label className='form-label fs-5 fw-bolder'>Appoint Role</label>

                                            <input
                                                type='text'
                                                className='form-control'
                                                placeholder='Search / Select an Employee'
                                                // value={employeeQuery}
                                                onChange={(e) => handleEmployeeChange(e, index)}
                                                onFocus={(e) => handleEmployeeChange(e, index)}
                                                ref={(el) => (employeeRefs.current[index] = el)}
                                                required
                                            />
                                            {activeDropdownIndex === index && employeeResults.length > 0 && (
                                                <ul className='form-control ' style={{
                                                    maxHeight: '200px', whiteSpace: 'nowrap', overflow: 'auto', position: 'absolute',
                                                    width: 'calc(42% - 4px)', // Adjust padding and border width if necessary
                                                    // top: '8px',
                                                    left: '54%',
                                                    zIndex: 999,
                                                    border: '1px solid #ced4da', // Border color for consistency
                                                    borderRadius: '0.25rem', // Border radius for consistency
                                                    backgroundColor: '#fff', // Background color for consistency
                                                }}>
                                                    {employeeResults.map((item, employeeIndex) => (
                                                        <li
                                                            key={employeeIndex}
                                                            onClick={handleEmployeeClick(item, index)}
                                                            style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                                                            className='hover-li'
                                                        >{`${item['first_name']} ${item['last_name']} - ${item['employee_id']}`}</li>
                                                    ))}
                                                </ul>
                                            )}

                                            {data?.user && (
                                                <div key={index} className="mt-4 d-flex align-items-center " >
                                                    <img src={data?.user?.profile_pic ? data?.user?.profile_pic : imagePath.userIcon} style={{ width: '34px', height: '34px', borderRadius: '17px', objectFit: 'contain' }} />
                                                    <div className='ms-4 d-flex flex-column'>
                                                        <label className='fs-5 fw-bolder '>{data?.user?.fullName}</label>
                                                        <label className='fs-6 fw-semibold '>{data?.user?.email}</label>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )
                }) : (<div></div>)}

                <div className=' mx-10 mw-100 mt-4 justify-content-center cursor-pointer d-flex fs-1 py-1' style={{ borderRadius: '6px', border: '1px #98A2B3 dotted', color: '#98A2B3' }}
                    onClick={() => handleAddApprovingAuthority()}>
                    +
                </div>

                <div className=' mt-10 d-flex align-items-center justify-content-center mb-4 mx-auto col-md-11'>
                    <button className='btn orange-tag fw-bolder me-8' style={{ width: '8rem' }}
                        onClick={() => handleClose()}>
                        Close
                    </button>
                    <button className='btn candi-btn2 fw-bolder text-white' style={{ width: '8rem' }}
                        onClick={(event) => handleSubmit(event)}>
                        {formType === 'create' ? 'Save' : 'Update'}
                    </button>
                </div>

                {showErrorMessage && (
                    <ShowError
                        handleClose={() => setShowErrorMessage(false)}
                        message={message}
                    />
                )}
                {loading && (
                    <LoaderView
                        message='Loading...'
                    />
                )}
            </div>
        </Modal>,
        modalsRoot
    )
};

//make this component available to the app
export default AddUpdateApprovingAuthorityPopup;
