//import liraries
import React, { useEffect, useState, useRef, useContext } from 'react'
import { useIntl } from 'react-intl'
import imagePath from '../../../constants/imagePath';
import CircularProgressBar from './components/CircularProgressBar';
import PositionInfoCard from './components/PositionInfoCard';
import { PageTitle } from '../../../_metronic/layout/core';
import GreetingCard from './components/GreetingCard';
import InsightsCard from './components/InsightsCard';
import RecentActivities from './components/RecentActivities';
import VacantPositionsDashboard from './components/VacantPositionsDashboard';
import { LoginContext } from '../../App';
import { useAuth } from '../auth/core/Auth';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import GroupSummary from './components/GroupSummary';
import ShowError from '../../../_metronic/partials/content/toasts/ShowError';
import TimeToHireTable from './components/TimeToHireTable';
import ApplicationsPerOpeningTable from './components/ApplicationsPerOpeningTable';
import OfferAcceptanceTable from './components/OfferAcceptanceTable';
import AttritionTable from './components/AttritionTable';
import ActionsAtTATTable from './components/ActionsAtTATTable';
import ActionsAfterTATTable from './components/ActionsAfterTATTable';
import UpcomingEvents from './components/UpcomingEvents';

const API_URL = process.env.REACT_APP_API_URL
const GET_JOBS_CANDIDATES_URL = `${API_URL}/analytics/dashboard-vacant-position`
const GET_INSIGHTS_URL = `${API_URL}/job/dashboard-insights`;
const GET_LEADERBOARD_DATA_URL = `${API_URL}/job/ta-leaderboard-data`

// create a component
const Dashboard = () => {
    const { loggedIn, setLoggedIn } = useContext(LoginContext)
    const { currentUser, auth, logout } = useAuth()
    const navigate = useNavigate()
    const [activeJobs, setActiveJobs] = useState(0)
    const [closedJobs, setClosedJobs] = useState(0)
    const [unattendedJobs, setUnattendedJobs] = useState(0);
    const [currentSummary, setCurrentSummary] = useState('performance');
    const [groupPositions, setGroupPositions] = useState<any>([]);
    const [news, setNews] = useState<any>();
    const [firstYearAttrition, setFirstYearAttrition] = useState('0');
    const [applicationsPerOpening, setApplicationsPerOpening] = useState('0');
    const [offerAcceptance, setOfferAcceptance] = useState('0');
    const [closedAfter30Days, setClosedAfter30Days] = useState('0');
    const [closedWithin30Days, setClosedWithin30Days] = useState('0');
    const [timeToHire, setTimeToHire] = useState('0');
    const [allTaLeads, setAllTaLeads] = useState<any>();
    const [recruiters, setRecruiters] = useState<any>();
    const [taLeadSelected, setTaLeadSelected] = useState<any>(false);
    const [currentTALead, setCurrentTALead] = useState<any>(null);
    const [currentRecruiter, setCurrentRecruiter] = useState<any>(null);
    const [dataType, setDataType] = useState('allPositions');
    const [loading, setLoading] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [message, setMessage] = useState('');

    const handleClickArrowLeftSummary = () => {
        setCurrentSummary(currentSummary === 'performance' ? 'events' : (currentSummary === 'events' ? 'group' : 'performance'));
    }

    const handleClickArrowRightSummary = () => {
        setCurrentSummary(currentSummary === 'performance' ? 'group' : (currentSummary === 'group' ? 'events' : 'performance'));
    }

    const intl = useIntl()

    const getTotalJobsAndCandidates = async () => {
        let masterRecruiter = 'masterRecruiter'
        let recruiter = 'recruiter'
        console.log('userRole: ', currentUser?.role)
        if (currentUser?.role === 'none') {
            return
        }
        axios
            .get(GET_JOBS_CANDIDATES_URL, {
                params: {
                    recruiter_id: currentUser?.role === recruiter ? currentUser?.user_id : 'admin',
                },
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                    'Content-Type': 'application/json',
                },
            })
            .then((res) => {

                let data = res.data
                let positionGroupCount = data?.positionGroup
                if (positionGroupCount) {
                    let latestPositionsData = positionGroupCount.slice(0, 3)
                    // setGroupPositions(latestPositionsData)
                    setGroupPositions(positionGroupCount)
                }

                let active = data?.positionsPosted
                let closed = data?.positionsHired
                let notPosted = data?.positionsNotPosted || 0;
                let notRaised = data?.positionsNotRaised || 0;
                let unattended = notPosted + notRaised;
                let totalJobs = active + closed + unattended;
                setActiveJobs(active)
                setClosedJobs(closed)
                setUnattendedJobs(unattended);

            })
            .catch((err) => {
                console.log('err', err)
            })
    }

    const getInsightsData = async () => {

        if (currentUser?.role === 'none') {
            return;
        }

        setLoading(true);
        await axios.get(GET_INSIGHTS_URL, {
            headers: {
                Authorization: `Bearer ${auth?.token}`,
                'Content-Type': 'application/json',
            }
        }).then((res) => {
            setLoading(false);
            let insightsData = res.data;
            console.log('newsData: ', insightsData?.data);
            let allNews = insightsData?.data;
            // getting latest news
            let newsData: any = [];
            if (allNews && allNews.length > 0) {
                newsData.push(allNews[0]);
            }
            setNews(newsData);
            setFirstYearAttrition(insightsData?.firstYearAttrition === '0.00' ? '0' : insightsData?.firstYearAttrition);
            setApplicationsPerOpening(insightsData?.averageApplicationsPerOpening === '0.00' ? '0' : insightsData?.averageApplicationsPerOpening);
            setOfferAcceptance(insightsData?.averageOfTotalAcceptedJob === '0.00' ? '0' : insightsData?.averageOfTotalAcceptedJob);
            setTimeToHire(insightsData?.averageTimeToHire === '0.00' ? '0' : insightsData?.averageTimeToHire);
            setClosedAfter30Days(insightsData?.closedAfter30Days === '0.00' ? '0' : insightsData?.closedAfter30Days);
            setClosedWithin30Days(insightsData?.closedWithin30Days === '0.00' ? '0' : insightsData?.closedWithin30Days);

        }).catch((error) => {
            setLoading(false);
            if (error.response && error.response.status === 400) {
                // showToast(error.response.data.message)
                setMessage(error.response.data.message);
                setShowErrorMessage(true);
            } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
                logout();
            } else if (error.response && error.response.data && error.response.data.error) {
                if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                    logout();
                } else {
                    setMessage(error.response.data.error.message);
                    setShowErrorMessage(true);
                }
            } else {
                setMessage(error.message);
                setShowErrorMessage(true);
            }
        })
    }

    const getTALeadData = async () => {

        // let id = currentUser?.role === 'masterRecruiter' ? 'admin' : currentUser?.user_id
        let id = currentUser?.user_id;
        // let url = `${GET_LEADERBOARD_DATA_URL}?ta_id=${id}`;
        await axios.get(GET_LEADERBOARD_DATA_URL, {
            headers: {
                Authorization: `Bearer ${auth?.token}`,
                'Content-Type': 'application/json',
            },
        }).then((res) => {

            let data = res?.data;
            let leaderboardData = data?.leaderboardData;
            let formattedList: any = [];

            for (let taLeadName in leaderboardData) {
                const taLeadData = leaderboardData[taLeadName];

                formattedList.push({
                    taName: taLeadName,
                    user_id: taLeadData?.taLeadId,
                    avgApplicationsPerOpening: taLeadData.avgApplicationsPerOpening,
                    averageTimeToHire: taLeadData.averageTimeToHire,
                    attritionRate: taLeadData.attritionRate,
                    acceptanceRate: taLeadData.acceptanceRate,
                    department: taLeadData.department,
                    profileImage: taLeadData.profileImage,
                    recruiters: taLeadData.recruiters,
                    rank: taLeadData.rank
                });

            }

            if (formattedList.length > 0) {
                console.log('recruitersData:ReducedLength: ', formattedList.length)
                setAllTaLeads(formattedList);

                if (currentUser?.role === 'ta_lead') {
                    let matchingUser = formattedList.find((lead) => lead.user_id === currentUser?.user_id);

                    // let taLeadData = {
                    //     taName: matchingUser?.taLeadName,
                    //     user_id: matchingUser?.user_id,
                    //     avgApplicationsPerOpening: insightsData?.averageApplicationsPerOpening,
                    //     averageTimeToHire: insightsData?.averageTimeToHire,
                    //     attritionRate: insightsData?.firstYearAttrition,
                    //     acceptanceRate: insightsData?.averageOfTotalAcceptedJob,
                    //     department: null,
                    //     profileImage: matchingUser?.profile_pic,
                    //     recruiters: null,
                    //     rank: 1
                    // }
                    setCurrentTALead(matchingUser);
                    setRecruiters(matchingUser?.recruiters);
                }
            }

        }).catch((error) => {
            if (error.response && error.response.status === 400) {
                setMessage(error.response.data.message);
                setShowErrorMessage(true);
            } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
                logout();
            } else if (error.response && error.response.data && error.response.data.error) {
                if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                    logout();
                } else {
                    setMessage(error.response.data.error.message);
                    setShowErrorMessage(true);
                }
            } else {
                setMessage(error.message);
                setShowErrorMessage(true);
            }
        });
    };

    useEffect(() => {
        (async () => {
            // if (currentUser?.role === 'masterRecruiter' || currentUser?.role === 'ta_head') {
            if (currentUser?.role !== 'recruiter') {
                getTALeadData();
            }
            getInsightsData();
            getTotalJobsAndCandidates()
        })()
    }, [])

    const showOverAllInsights = () => {
        if (taLeadSelected) {
            setTaLeadSelected(false);
            setCurrentTALead(null);
            setRecruiters(null);
            setCurrentRecruiter(null);
            getInsightsData();
        }
        setDataType('allPositions');
    }

    const handleTALeadChange = (e) => {
        const value = e.target.value;
        // Find the TA Lead with the matching taName
        const selectedTALead = allTaLeads.find((taLead) => taLead.taName === value);

        // Perform actions with the selected TA Lead
        if (selectedTALead) {
            setTaLeadSelected(true);
            setCurrentTALead(selectedTALead);
            console.log("Selected TA Lead:", selectedTALead);
            showTALeadInsights(selectedTALead);
            setRecruiters(selectedTALead?.recruiters);
            setCurrentRecruiter(null);
        } else {
            setTaLeadSelected(false);
            setCurrentTALead(null);
            setRecruiters(null);
            setCurrentRecruiter(null);
        }
    }

    const handleRecruiterChange = (e) => {
        const value = e.target.value;
        // Find the Recruiter with the matching taName
        const selectedRecruiter = recruiters.find((recruiter) => recruiter.name === value);

        // Perform actions with the selected TA Lead
        if (selectedRecruiter) {
            setCurrentRecruiter(selectedRecruiter);
            // showRecruiterInsights(selectedRecruiter);
            showTALeadInsights(selectedRecruiter);
        } else {
            // setRecruiters(null);
            setCurrentRecruiter(null);
        }
    }

    const showTALeadInsights = (user) => {

        setFirstYearAttrition(user?.attritionRate);
        setApplicationsPerOpening(user?.avgApplicationsPerOpening);
        setOfferAcceptance(user?.acceptanceRate);
        setTimeToHire(user?.averageTimeToHire);
        setClosedAfter30Days(user?.closedAfter30Days);
        setClosedWithin30Days(user?.closedWithin30Days)
    }

    const removeCurrentTALead = () => {
        setTaLeadSelected(false);
        setCurrentTALead(null);
        setRecruiters(null);
        setCurrentRecruiter(null);
        getInsightsData();
    }
    const removeCurrentRecruiter = () => {
        showTALeadInsights(currentTALead);
        setCurrentRecruiter(null);
    }


    const showAvgTimeToHireInsights = () => {
        if (dataType === 'hireTime') {
            setDataType('allPositions');
        } else {
            setDataType('hireTime');
        }
    }
    const showApplicationsPerOpeningInsights = () => {
        if (dataType === 'applicationsPerOpening') {
            setDataType('allPositions');
        } else {
            setDataType('applicationsPerOpening');
        }
    }
    const showOfferAcceptanceInsights = () => {
        if (currentUser?.role === 'recruiter') {
            return;
        }
        if (dataType === 'offerAcceptance') {
            setDataType('allPositions');
        } else {
            setDataType('offerAcceptance');
        }
    }
    const showAttritionInsights = () => {
        if (currentUser?.role === 'recruiter') {
            return;
        }
        if (dataType === 'attrition') {
            setDataType('allPositions');
        } else {
            setDataType('attrition');
        }
    }
    const showCompletedWithTatInsights = () => {
        if (dataType === 'completedWithTAT') {
            setDataType('allPositions');
        } else {
            setDataType('completedWithTAT');
        }
    }
    const showCompletedAfterTatInsights = () => {
        if (dataType === 'completedAfterTAT') {
            setDataType('allPositions');
        } else {
            setDataType('completedAfterTAT');
        }
    }

    return (
        <div>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
            <div className='col-md-12 dashboard-top'>
                {/* <h1>Dashboard</h1> */}
                <h3>
                    <i className='bi bi-house text-white' style={{ fontSize: '1.5rem' }}></i>
                    <i className='bi bi-dot text-white' style={{ fontSize: '1rem' }}></i>My Dashboard
                </h3>
            </div>
            <div className='d-flex col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                <div className='d-flex flex-column col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4'>
                    {/* overall summary card */}
                    <div className='card-bg shadow-sm' style={{ height: '29.8rem' }}>
                        <div className='d-flex px-6 pt-6 align-items-center'>
                            <label className='fs-6 fw-bolder '>{currentSummary === 'performance' ? 'Overall Performance Summary' : (currentSummary === 'group' ? 'Group Summary' : 'Upcoming Events')}</label>
                            <div className='d-flex ms-auto align-items-center'>
                                <img src={imagePath.ic_arrow_left_circle}
                                    width={18} height={18}
                                    style={{ cursor: 'pointer', zIndex: 1, marginRight: '2px' }}
                                    onClick={() => handleClickArrowLeftSummary()} />

                                <img src={imagePath.ic_arrow_right_circle}
                                    width={18} height={18}
                                    style={{ cursor: 'pointer', zIndex: 1 }}
                                    onClick={() => handleClickArrowRightSummary()} />
                            </div>
                        </div>
                        <hr className='mx-6' style={{ backgroundColor: '#484848', height: '1px' }}></hr>
                        {currentSummary === 'performance' && (
                            <div style={{ height: '22rem' }}>
                                <div className='justify-content-center align-items-center mx-auto mb-2' style={{ width: '190px' }}>
                                    <CircularProgressBar
                                        inProgress={activeJobs}
                                        closedPositions={closedJobs}
                                        unAttendedPositions={unattendedJobs}
                                    // inProgress={30}
                                    // closedPositions={1}
                                    // unAttendedPositions={69}
                                    />
                                </div>
                                <div className='d-flex justify-content-center align-items-center mx-2 mb-4' >
                                    <PositionInfoCard
                                        positionType='InProgress'
                                        positionValue={activeJobs}
                                    />
                                    <PositionInfoCard
                                        positionType='Closed'
                                        positionValue={closedJobs}
                                    />
                                    <PositionInfoCard
                                        positionType='Unattended'
                                        positionValue={unattendedJobs}
                                    />
                                    {/* <PositionInfoCard />
                                <PositionInfoCard /> */}
                                </div>

                            </div>
                        )}
                        {currentSummary === 'group' && (
                            <div style={{ height: '22rem' }}>
                                <GroupSummary
                                    groupPositions={groupPositions}
                                />
                            </div>
                        )}

                        {currentSummary === 'events' && (
                            <div style={{ height: '22rem' }}>
                                <UpcomingEvents />
                            </div>
                        )}

                        <div className='d-flex justify-content-center align-items-center' style={{ zIndex: '1', position: 'relative' }}>
                            <div className={`${currentSummary === 'performance' ? 'orange-dot' : 'gray-dot'}`}
                                onClick={() => setCurrentSummary('performance')}></div>
                            <div className={`mx-2 ${currentSummary === 'group' ? 'orange-dot' : 'gray-dot'}`}
                                onClick={() => setCurrentSummary('group')}></div>
                            <div className={`${currentSummary === 'events' ? 'orange-dot' : 'gray-dot'}`}
                                onClick={() => setCurrentSummary('events')}></div>
                        </div>

                    </div>
                    {/* Recent activities */}
                    <div className='mt-8'>
                        <RecentActivities />
                    </div>
                </div>
                <div className='col-xs-8 col-sm-8 col-md-8 col-lg-8 col-xl-8'>
                    <div className='ms-4'>
                        <GreetingCard
                            news={news} />
                    </div>

                    {(currentUser?.role !== 'recruiter') ? (
                        <div className='d-flex ms-4 mt-4 align-items-center'>
                            <img src={taLeadSelected ? imagePath.ic_group_orange_holo : imagePath.ic_group_orange_filled}
                                style={{ cursor: 'pointer', width: '43px', height: '43px' }}
                                className={`me-4 ${taLeadSelected ? 'holo-group-icon-style' : ''}`}
                                onClick={() => showOverAllInsights()} />
                            {(currentUser?.role === 'masterRecruiter' || currentUser?.role === 'ta_head') && (
                                <div className='me-4'>
                                    <select
                                        style={{ width: '14rem' }}
                                        name='status'
                                        data-control='select2'
                                        data-hide-search='true'
                                        className='form-select '
                                        defaultValue='Select an option'
                                        placeholder='Select an option'
                                        value={currentTALead?.taName || ''}
                                        onChange={(event) => handleTALeadChange(event)}
                                        disabled={loading}
                                    >
                                        <option value='Select an option'>TA Lead</option>
                                        {allTaLeads &&
                                            allTaLeads.map((taLead) => (
                                                // <option key={taLead.taName} value={taLead.taName}>
                                                //     {taLead.taName}
                                                // </option>
                                                <option key={taLead.taName} value={taLead.taName}>
                                                    <div className="d-flex align-items-center"> {/* Wrap in a div for flex layout */}
                                                        <img src={taLead.profileImage ? taLead.profileImage : imagePath.userIcon}
                                                            alt="Profile"
                                                            style={{ width: '24px', height: '24px', marginRight: '8px', borderRadius: '50%', border: '1px #F36523 solid' }} />
                                                        <span>{taLead.taName}</span> {/* TA Name */}
                                                    </div>
                                                </option>
                                            ))}
                                    </select>
                                </div>)}
                            {currentTALead ? (<div className='me-4'>
                                <select
                                    style={{ width: '14rem' }}
                                    name='status'
                                    data-control='select2'
                                    data-hide-search='true'
                                    className='form-select '
                                    defaultValue='Select an option'
                                    placeholder='Select an option'
                                    value={currentRecruiter?.name || ''}
                                    onChange={(event) => handleRecruiterChange(event)}
                                    disabled={loading}
                                >
                                    <option value='Select an option'>Recruiter</option>
                                    {recruiters &&
                                        recruiters.map((recruiter) => (
                                            <option key={recruiter?.user_id} value={recruiter?.name}>
                                                {recruiter?.name}
                                            </option>
                                        ))}
                                </select>
                            </div>) : (<div></div>)}

                            {(currentTALead && (currentUser?.role === 'masterRecruiter' || currentUser?.role === 'ta_head')) ? (
                                <div className='d-flex shadow-sm align-items-center ps-4 pe-2 py-2 me-4'
                                    style={{ height: '28px', width: '12rem', backgroundColor: 'white', borderRadius: '24px', color: '#F57536' }}>

                                    <label className='fs-6 me-2' style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{currentTALead.taName}</label>
                                    <img src={imagePath.ic_close_circle} width={24} height={24} style={{ cursor: 'pointer', padding: '2px', marginLeft: 'auto' }}
                                        onClick={() => removeCurrentTALead()} />

                                </div>) : (<div></div>)}

                            {currentRecruiter ? (
                                <div className='d-flex shadow-sm align-items-center ps-4 pe-2 py-2 me-4'
                                    style={{ height: '28px', width: '12rem', backgroundColor: 'white', borderRadius: '24px', color: '#F57536' }}>

                                    <label className='fs-6 me-2' style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{currentRecruiter.name}</label>
                                    <img src={imagePath.ic_close_circle} width={24} height={24} style={{ cursor: 'pointer', padding: '2px', marginLeft: 'auto' }}
                                        onClick={() => removeCurrentRecruiter()} />

                                </div>) : (<div></div>)}

                        </div>) : (<div className='mt-18'></div>)
                    }
                    <div className=' d-flex col-md-12' style={{ overflow: 'auto' }}>
                        <InsightsCard
                            title='Avg. Time To Hire'
                            value={timeToHire}
                            unit='Days'
                            isActive={dataType === 'hireTime' ? true : false}
                            onClickCard={showAvgTimeToHireInsights}
                            
                        />
                        <InsightsCard
                            title='Avg. Applications Per Openings'
                            value={applicationsPerOpening}
                            unit=''
                            isActive={dataType === 'applicationsPerOpening' ? true : false}
                            onClickCard={showApplicationsPerOpeningInsights}
                            
                        />
                        <InsightsCard
                            title='No. of Cases Completed with TAT'
                            value={closedWithin30Days}
                            unit=''
                            isActive={dataType === 'completedWithTAT' ? true : false}
                            onClickCard={showCompletedWithTatInsights}
                            
                        />
                        <InsightsCard
                            title='No. of Cases Completed After TAT'
                            value={closedAfter30Days}
                            unit=''
                            isActive={dataType === 'completedAfterTAT' ? true : false}
                            onClickCard={showCompletedAfterTatInsights}
                            
                        />
                        <InsightsCard
                            title='Avg. Offer Acceptance'
                            value={offerAcceptance}
                            unit='%'
                            isActive={dataType === 'offerAcceptance' ? true : false}
                            onClickCard={showOfferAcceptanceInsights}
                            
                        />
                        <InsightsCard
                            title='Avg. First Year Attrition'
                            value={firstYearAttrition}
                            unit='%'
                            isActive={dataType === 'attrition' ? true : false}
                            onClickCard={showAttritionInsights}
                            
                        />

                    </div>

                    {/* Vacant Positions */}
                    {dataType === 'allPositions' && (
                        <div className='ms-4'>
                            <VacantPositionsDashboard
                                taLead={currentTALead}
                                recruiter={currentRecruiter} />
                        </div>
                    )}
                    {dataType === 'hireTime' && (
                        <div className='card-bg shadow-sm ms-4 px-4 py-6'>
                            <div>
                                <TimeToHireTable
                                    taLead={currentTALead}
                                    recruiter={currentRecruiter} />
                            </div>
                        </div>
                    )}
                    {dataType === 'applicationsPerOpening' && (
                        <div className='card-bg shadow-sm ms-4 px-4 py-6'>
                            <div>
                                <ApplicationsPerOpeningTable
                                    taLead={currentTALead}
                                    recruiter={currentRecruiter} />
                            </div>
                        </div>
                    )}
                    {dataType === 'offerAcceptance' && (
                        <div className='card-bg shadow-sm ms-4 px-4 py-6'>
                            <div>
                                <OfferAcceptanceTable
                                    taLead={currentTALead}
                                    recruiter={currentRecruiter} />
                            </div>
                        </div>
                    )}
                    {dataType === 'attrition' && (
                        <div className='card-bg shadow-sm ms-4 px-4 py-6'>
                            <div>
                                <AttritionTable
                                    taLead={currentTALead}
                                    recruiter={currentRecruiter} />
                            </div>
                        </div>
                    )}
                    {dataType === 'completedWithTAT' && (
                        <div className='card-bg shadow-sm ms-4 px-4 py-6'>
                            <div>
                                <ActionsAtTATTable
                                    taLead={currentTALead}
                                    recruiter={currentRecruiter} />
                            </div>
                        </div>
                    )}
                    {dataType === 'completedAfterTAT' && (
                        <div className='card-bg shadow-sm ms-4 px-4 py-6'>
                            <div>
                                <ActionsAfterTATTable
                                    taLead={currentTALead}
                                    recruiter={currentRecruiter} />
                            </div>
                        </div>
                    )}

                </div>

            </div>
            {showErrorMessage && (
                <ShowError
                    handleClose={() => setShowErrorMessage(false)}
                    message={message}
                />
            )}
        </div>
    );
};


//make this component available to the app
export default Dashboard;
