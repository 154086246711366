//import liraries
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import './ModelStyless.css'
import Layout from '../images/Layout-4-blocks (3).png'
import { useAuth } from '../../../../app/modules/auth/core/Auth'
import { useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import interviewheadericon from '../images/interview@2x copy.png'
import Box from '@mui/material/Box'
import { TextField } from '@mui/material'
import { makeStyles } from '@material-ui/core/styles';
import Usericon from './img/two-users@2x.png'
import Timericon from './img/Group 10169@2x.png'
import Copyicon from './img/interview2x.png'
import Descriptionicon from './img/description@2x.png'
import InformationIcon from './img/information (3)@2x.png'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import dayjs, { Dayjs } from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import Stack from '@mui/material/Stack'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import ExprienceIcon from './img/experience@2x.png'
import UserIcon from './img/Group 9681.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';
import ShowError from '../../content/toasts/ShowError'
import imagePath from '../../../../constants/imagePath'

const API_URL = process.env.REACT_APP_API_URL
const GET_ALL_USERS_URL = `${API_URL}/auth/user-list`;
const GET_ALL_CANDIDATES_URL = `${API_URL}/auth/candidate-list`;
export const GET_ALL_RECRUITERS_URL = `${API_URL}/recruiter/get`;
export const GET_RECRUITER_URL = `${API_URL}/recruiter/`;
const CREATE_PANEL_URL = `${API_URL}/panel/create-multiple`;
const SEND_EMAIL_URL = `${API_URL}/panel/send-email-to-multiple-members`;
const RESCHEDULE_INTERVIEW_URL = `${API_URL}/panel/reschedule-interview-bulk`;

type Props = {
    show: boolean
    handleClose: () => void
    candidates: any
    handleSuccess: () => void
    currentStage: any
    newStage: any
}

const modalsRoot = document.getElementById('root-modals') || document.body

type InputProps = {
    members?: string
    duration?: string
    date?: string
    link?: string
    description?: string
}

interface Recruiter {
    user_id: string;
    name: string;
    email: string;
    currentTarget: string
}

// create a component
const ScheduleInterViewMultipleCandidatesStage1 = ({ show, handleClose, candidates, handleSuccess, currentStage, newStage }: Props) => {
    console.log('nextStage: ', newStage)

    const [allUsers, setAllUsers] = useState<any>([]);
    const [searchKey, setSearchKey] = useState('');
    const [allCandidates, setAllCandidates] = useState<any>([]);
    const [selectedPanelMembers, setSelectedPanelMembers] = useState<any>([]);
    const [searchRecord, setsearchRecord] = useState<Recruiter[]>([]);
    const [interviewType, setInterviewType] = useState();
    const [interviewAddress, setInterviewAddress] = useState();
    const [searchTerm, setSearchTerm] = useState<any>('');
    const [highlightedIndex, setHighlightedIndex] = useState(-1);
    const [description, setDescription] = useState('description');
    const [loading, setLoading] = useState(false);
    const [value, setValue] = React.useState<Dayjs | null>(dayjs());
    const [formData, setFormData] = useState<InputProps>();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [message, setMessage] = useState('');
    const { currentUser, auth, logout } = useAuth()

    let LocalResult: any = {}
    const currentDate = dayjs(); // Get the current date


    const getAllUsers = async () => {
        await axios.get(GET_ALL_USERS_URL, {
            params: {
                role: 'all',
                limit: 'all',
                search: searchKey
            },
            headers: {
                Authorization: `Bearer ${auth?.token}`,
                'Content-Type': 'application/json',
            },
        }).then((res) => {
            let users = res?.data?.data;
            setAllUsers(users);
            if (searchKey) {
                setsearchRecord(users);
            }
        }).catch((err) => {
            console.error('err: ', err);
        })
    }

    const getAllCandidates = async () => {
        console.log('selectedCandidate: ', candidates);
        let user_ids: any = [];
        if (!candidates) {
            return;
        }

        candidates?.map((candidateApplication) => {
            user_ids.push(candidateApplication?.candidateData?.user_id);
        });
        let data = {
            user_ids: user_ids
        }
        await axios.post(GET_ALL_CANDIDATES_URL, data, {
            headers: {
                Authorization: `Bearer ${auth?.token}`,
                'Content-Type': 'application/json',
            },
        }).then((res) => {
            let allApplicants = res?.data?.users;
            let fetchedCandidates: any = []
            if (allApplicants) {
                allApplicants.map((applicant, index) => {
                    let data = {
                        "user_id": applicant?.user_id,
                        "profile_pic": applicant?.profile_pic,
                        "first_name": applicant?.first_name,
                        "last_name": applicant?.last_name,
                        "email": applicant?.email,
                        "time_from": '',
                        "time_to": ''
                    }
                    fetchedCandidates.push(data);
                })
            }
            setAllCandidates(fetchedCandidates);
        }).catch((err) => {
            console.error('err: ', err);
        })

    }

    useEffect(() => {
        
        (async () => {
            await getAllCandidates();            
        })();
    }, []);

    useEffect(() => {
        (async () => {
            await getAllUsers();
            
        })();
    }, [searchKey]);


    const handleSearch = (event) => {
        const searchTerm = event.target.value.toLowerCase();
        setSearchTerm(searchTerm);

        if (!searchTerm) {
            setSearchKey('');
            setsearchRecord([]);
        } else {
            if (searchTerm.length >= 3) {
                setSearchKey(searchTerm);
            }
            // const filtered = allUsers?.filter((user) => {
            //     if (!user) {
            //         return false;
            //     }
            //     const name = user['first_name']?.toLowerCase();
            //     const id = user['employee_id']?.toLowerCase();
            //     return name?.includes(searchTerm) || id?.includes(searchTerm);
            // });
            // setsearchRecord(filtered);
        }
    };
    const handleKeyDown = (event) => {
        if (event.key === 'ArrowDown') {
            event.preventDefault();
            setHighlightedIndex((prevIndex) =>
                prevIndex < searchRecord.length - 1 ? prevIndex + 1 : prevIndex
            );
        } else if (event.key === 'ArrowUp') {
            event.preventDefault();
            setHighlightedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
        }
    };

    const handleInterviewerSelect = (interviewer) => {
        setSearchTerm('');
        const userId = interviewer.user_id;
        const index = allUsers.findIndex((obj) => obj.user_id === userId);

        if (selectedPanelMembers.length >= 5) {
            setMessage("Can't select panelists more than 5.");
            setShowErrorMessage(true);
            setSearchKey('');
            setSearchTerm('');
            setsearchRecord([]);
            return;
        }
        // Check if the interviewer is already in selectedPanelMembers
        const isAlreadySelected = selectedPanelMembers.some((member) => member.user_id === userId);
        if (!isAlreadySelected) {
            // If not already selected, add the interviewer to the list
            const updatedPanelMembers = [...selectedPanelMembers, interviewer];
            setSelectedPanelMembers(updatedPanelMembers);
        }
        setSearchTerm('');
        setsearchRecord([]);

        //     setSelectedInterviewers((prevInterviewers) => {
        //         const updatedInterviewers = [...prevInterviewers, interviewer];
        //         const name = updatedInterviewers.map((item) => item.first_name);
        //         const email = updatedInterviewers.map((item) => item.email);
        //         const member_id = updatedInterviewers.map((item) => item.user_id);

        //         const candResult = {
        //             // jobapp_id,
        //             jobapp_id: 'jobapp_id',
        //             name: 'stage1',
        //             // member_id: user_id,
        //             member_id: 'user_id',
        //             // email: cand_email,
        //             email: 'cand_email',
        //             index: '0',
        //             availability: 'false',
        //             member_type: 'candidate',
        //         };

        //         const jobPosterEmail = items.email;
        //         const jobPosterID = items.user_id;

        //         const LocalResult = {
        //             // jobapp_id,
        //             jobapp_id: 'jobapp_id',
        //             name: 'stage1',
        //             member_id: jobPosterID,
        //             email: jobPosterEmail,
        //             index: '0',
        //             availability: 'false',
        //             member_type: 'job-poster',
        //         };

        //         const result = updatedInterviewers.map(({ user_id, email }) => ({
        //             member_id: user_id,
        //             email: email,
        //             // jobapp_id,
        //             jobapp_id: 'jobapp_id',
        //             index: '0',
        //             name: 'stage1',
        //             availability: 'false',
        //             member_type: 'panel',
        //         }));

        //         const updatedResult = result.concat(candResult, LocalResult);
        //         console.log({ updatedResult })
        //         setPanelName((prevPanelName) => ({ ...prevPanelName, result: updatedResult }));
        //         setPanelEmail((prevPanelEmail) => ({ ...prevPanelEmail, email }));
        //         setPanelId((prevPanelId) => ({ ...prevPanelId, member_id }));

        //         return updatedInterviewers;
        //     });
        // }

        // setSearchTerm('');
        // setsearchRecord([]);
    };

    const removeInterviewer = (event, index) => {
        event.preventDefault();
        // Create a copy of the selectedPanelMembers array
        const updatedPanelMembers = [...selectedPanelMembers];
        // Use splice to remove the user at the specified index
        updatedPanelMembers.splice(index, 1);
        // Update the state with the modified array
        setSelectedPanelMembers(updatedPanelMembers);

    };

    const handleChange = (newValue: Dayjs | null) => {
        setValue(newValue)
    }

    const handelInterviewTypeChange = (event) => {
        const value = event.target.value;
        setInterviewType(value);
    }
    const onHandleLinkChange = (event) => {
        const value = event.target.value;
        setInterviewAddress(value);
    }

    const handleOnBlurTimeFrom = (event, index) => {
        // let data = [...allCandidates];

        // data?.map((candidate, i) => {
        //     const timeFrom = candidate['time_from'];
        //     // Check if time_from is same as time_to for the same candidate
        //     const timeTo = candidate['time_to'];

        //     if (timeFrom === timeTo) {
        //         setMessage('Time From cannot be same as Time To for the same candidate.');
        //         setShowErrorMessage(true);
        //     } else {
        //         // Loop through allCandidates to compare time_from values
        //         for (let i = 0; i < data.length; i++) {
        //             if (i !== index && data[i]['time_from'] === timeFrom) {
        //                 setMessage('Time From cannot be same as Time From of another candidate.');
        //                 setShowErrorMessage(true);
        //                 // Reset time_to to the default value
        //                 data[index]['time_from'] = '00:00';
        //                 break;
        //             }
        //         }
        //         // Check if time_to is same as time_to of other candidates
        //         for (let i = 0; i < data.length; i++) {
        //             if (i !== index && data[i]['time_to'] === timeTo) {
        //                 setMessage('Time To cannot be same as Time To of another candidate.');
        //                 setShowErrorMessage(true);
        //                 // Reset time_to to the default value
        //                 data[index]['time_to'] = '00:00';
        //                 break;
        //             }
        //         }
        //     }
        //     // Check if time_from is greater than time_to for the same candidate
        //     if (timeTo && timeFrom > timeTo) {
        //         setMessage('Time From cannot be greater than Time To for the same candidate.');
        //         setShowErrorMessage(true);
        //         // Reset time_to to the default value
        //         data[index]['time_from'] = '00:00';
        //         data[index]['time_to'] = '00:00';
        //     }


        // })
        // setAllCandidates(data);
    }

    const onTimeChange = (event, index) => {
        let data = [...allCandidates];
        const { name, value } = event.target;
        console.log('timeValue: ', value);
        data[index][name] = value;

        // Check if time_from is same as time_from of other candidates
        if (name === 'time_from') {
            const timeFrom = value;
            // Check if time_from is same as time_to for the same candidate
            const timeTo = data[index]['time_to'];
            if (timeFrom === timeTo) {
                setMessage('Time From cannot be same as Time To for the same candidate.');
                setShowErrorMessage(true);
                // Reset time_to to the default value
                data[index][name] = '00:00';
            } else {
                // Loop through allCandidates to compare time_from values
                for (let i = 0; i < data.length; i++) {
                    if (i !== index && data[i]['time_from'] === timeFrom) {
                        setMessage('Time From cannot be same as Time From of another candidate.');
                        setShowErrorMessage(true);
                        // Reset time_to to the default value
                        data[index][name] = '00:00';
                        break;
                    }
                }
            }
            // Check if time_from is greater than time_to for the same candidate
            // if (timeTo && timeFrom > timeTo) {
            //     setMessage('Time From cannot be greater than Time To for the same candidate.');
            //     setShowErrorMessage(true);
            //     // Reset time_to to the default value
            //     data[index][name] = '00:00';
            // }
        }

        if (name === 'time_to') {
            const timeTo = value;
            // Check if time_to is same as time_from for the same candidate
            const timeFrom = data[index]['time_from'];
            if (timeTo === timeFrom) {
                setMessage('Time To cannot be same as Time From for the same candidate.');
                setShowErrorMessage(true);
                // Reset time_to to the default value
                data[index][name] = '00:00';
            } else {
                // Check if time_to is same as time_to of other candidates
                for (let i = 0; i < data.length; i++) {
                    if (i !== index && data[i]['time_to'] === timeTo) {
                        setMessage('Time To cannot be same as Time To of another candidate.');
                        setShowErrorMessage(true);
                        // Reset time_to to the default value
                        data[index][name] = '00:00';
                        break;
                    }
                }
            }
            // Check if time_to is less than time_from for the same candidate
            // if (timeFrom && timeTo < timeFrom) {
            //     setMessage('Time To cannot be less than Time From for the same candidate.');
            //     setShowErrorMessage(true);
            //     // Reset time_to to the default value
            //     data[index][name] = '00:00';
            // }
        }

        setAllCandidates(data);
    }

    // const onTimeChange = (event, index) => {
    //     let data = [...allCandidates];
    //     const { name, value } = event.target;

    //     // Convert time strings to Date objects for comparison
    //     const timeValue = new Date(`1970-01-01T${value}`);
    //     const prevToValue = index > 0 ? new Date(`1970-01-01T${data[index - 1]['time_to']}`) : null;

    //     if (name === 'time_from') {
    //         // Ensure "To" time is greater than "From" time
    //         const toValue = data[index]['time_to'];
    //         const toTimeValue = toValue ? new Date(`1970-01-01T${toValue}`) : null;

    //         if (toTimeValue && toTimeValue <= timeValue) {
    //             // Reset "To" time if it's not greater than "From" time
    //             data[index]['time_to'] = '';
    //             setMessage("Time From can't be greater than Time To.");
    //             setShowErrorMessage(true);
    //         }

    //         // Check against the "To" time of the previous candidate
    //         if (prevToValue && prevToValue >= timeValue) {
    //             // Reset "From" time if it's not greater than the previous "To" time
    //             data[index]['time_from'] = '';
    //             setMessage("Value of Time From can't be less than Value of Time To of previous candidate. ");
    //             setShowErrorMessage(true);
    //         }
    //     } else if (name === 'time_to') {
    //         // Ensure "From" time is less than "To" time
    //         const fromValue = data[index]['time_from'];
    //         const fromTimeValue = fromValue ? new Date(`1970-01-01T${fromValue}`) : null;

    //         if (fromTimeValue && fromTimeValue >= timeValue) {
    //             // Reset "From" time if it's not less than "To" time
    //             data[index]['time_from'] = '';
    //             setMessage("Time To can't be less than Time From.");
    //             setShowErrorMessage(true);
    //         }
    //     }

    //     // Update the state with the modified data
    //     setAllCandidates(data);
    // };

    const handleInputs = (e) => {
        e.preventDefault()
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }

    const onDescriptionChange = (value) => {
        setDescription(value);
    };

    const getRequiredValue = (key, candidateId) => {
        const candidate = allCandidates.find(candidate => candidate.user_id === candidateId);
        // If a matching candidate is found, return the time_from value; otherwise, return null
        return candidate ? candidate[key] : null;
    }

    // find the shortest value of time_from
    const getShortestTime = (candidates) => {
        console.log('smallestTimeFrom: candidates: ', candidates);
        const timeFromValues = Object.values(candidates).map((candidate: any) => candidate['time_from']);
        const smallestTimeFrom = timeFromValues.reduce((min, time) => (time < min ? time : min), timeFromValues[0]);
        // console.log('smallestTimeFrom: ', smallestTimeFrom);
        return smallestTimeFrom;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        let jobapp_list: any = [];
        if (!candidates) {
            setMessage('Candidates are not available, Please select one or more candidates first and try again.');
            setShowErrorMessage(true);
            return;
        }
        if (!selectedPanelMembers || selectedPanelMembers.length <= 0) {
            setMessage('Please select one or more Panel Members first and try again.');
            setShowErrorMessage(true);
            return;
        }

        let panel: any = [];

        candidates?.map((candidateApplication) => {
            jobapp_list.push(candidateApplication?.jobapp_id);

            let memberInfo = {
                jobapp_list: jobapp_list,
                name: newStage,
                member_id: candidateApplication?.candidateData?.user_id,
                email: candidateApplication?.candidateData?.email,
                index: '0',
                availability: 'false',
                member_type: 'candidate',
            }
            panel.push(memberInfo)
        });
        selectedPanelMembers?.map((panelMember) => {
            let memberInfo = {
                jobapp_list: jobapp_list,
                name: newStage,
                member_id: panelMember?.user_id,
                email: panelMember?.email,
                index: '0',
                availability: 'false',
                member_type: 'panel',
            }
            panel.push(memberInfo)
        });

        // adding current recruiter as job  poster
        let memberInfo = {
            jobapp_list: jobapp_list,
            name: newStage,
            member_id: currentUser?.user_id,
            email: currentUser?.email,
            index: '0',
            availability: 'false',
            member_type: 'job-poster',
        }
        panel.push(memberInfo)

        if (currentStage === newStage) {

            // reschedule the interview
            setLoading(true);
            let data = {
                jobapp_list: jobapp_list,
                getStage: currentStage,
                panel: panel
            };
            await axios.post(RESCHEDULE_INTERVIEW_URL, data, {
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                    'Content-Type': 'application/json',
                },
            }).then((res) => {
                // setLoading(false);
                sendEmails();

            }).catch((error) => {
                setLoading(false);
                if (error.response && error.response.status === 400) {
                    // showToast(error.response.data.message)
                    setMessage(error.response.data.message);
                    setShowErrorMessage(true);
                } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
                    logout();
                } else if (error.response && error.response.data && error.response.data.error) {
                    if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                        logout();
                    } else {
                        setMessage(error.response.data.error.message);
                        setShowErrorMessage(true);
                    }
                } else {
                    setMessage(error.message);
                    setShowErrorMessage(true);
                }
            })


        } else {
            setLoading(true);
            let data = { panel: panel };
            await axios.post(CREATE_PANEL_URL, data, {
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                    'Content-Type': 'application/json',
                },
            }).then((res) => {

                sendEmails();

            }).catch((error) => {
                setLoading(false);
                if (error.response && error.response.status === 400) {
                    // showToast(error.response.data.message)
                    setMessage(error.response.data.message);
                    setShowErrorMessage(true);
                } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
                    logout();
                } else if (error.response && error.response.data && error.response.data.error) {
                    if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                        logout();
                    } else {
                        setMessage(error.response.data.error.message);
                        setShowErrorMessage(true);
                    }
                } else {
                    setMessage(error.message);
                    setShowErrorMessage(true);
                }
            });
        }



    }

    const sendEmails = async () => {

        let jobapp_list: any = [];
        candidates?.map((candidateApplication) => {
            jobapp_list.push(candidateApplication?.jobapp_id);
        });
        // implement API to send email of interview invitation
        if (!value) {
            setLoading(false);
            setMessage('Please select the interview date first and try again.');
            setShowErrorMessage(true);
            return;
        }
        if (!interviewType) {
            setLoading(false);
            setMessage('Please select the interview type first and try again.');
            setShowErrorMessage(true);
            return;
        }
        // Convert the ISO date string to a Date object
        let date = new Date(value["$d"]);
        // Format the date in the desired format "DD/MM/YYYY"
        let formattedDate = new Intl.DateTimeFormat('en', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        }).format(date);

        let candidatesData = {};
        candidates?.forEach((candidateApplication, index) => {
            let fullName = candidateApplication?.candidateData?.fullName;
            let candidateId = candidateApplication?.candidateData?.user_id;
            let timeFrom = getRequiredValue('time_from', candidateId);
            let timeTo = getRequiredValue('time_to', candidateId);

            candidatesData[index] = {
                candidate_name: fullName,
                candidate_id: candidateId,
                jobapp_id: candidateApplication?.jobapp_id,
                time_from: timeFrom,
                time_to: timeTo,
            };

        });
        const shortestTime = getShortestTime(allCandidates);
        if (!shortestTime) {
            setLoading(false);
            setMessage('Shortest time not found, Please change the interview times and try again.');
            setShowErrorMessage(true);
            return
        }
        if (!interviewAddress) {
            setLoading(false);
            setMessage('Please add Address or Meeting link first and try again.');
            setShowErrorMessage(true);
            return
        }
        let interviewDetails = {
            "interview_date": formattedDate,
            "interview_time": shortestTime,
            "interview_type": interviewType,
            "name": newStage,
            "address": interviewAddress,
            "description": description,
            // candidatesData
            "candidates": candidatesData
        }
        let emailData = {
            interview_details: interviewDetails,
            jobapp_list: jobapp_list,
            name: newStage,
            currentStage: currentStage,
            "newStage": newStage,
            "approver": "PANEL_1_APPROVAL"
        }
        // console.log('sendEmailRequestBody: ', emailData);
        await axios.post(SEND_EMAIL_URL, emailData, {
            headers: {
                Authorization: `Bearer ${auth?.token}`,
                'Content-Type': 'application/json',
            },
        }).then((resEmail) => {
            setLoading(false);
            handleSuccess();

        }).catch((errEmail) => {
            setLoading(false);
            console.error('err: ', errEmail);
        });
    }

    return (
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            width='104% !important'
            dialogClassName='modal-dialog modal-dialog-centered mw-900px'
            show={show}
            // onHide={handleClose}
            backdrop={true}
        >
            <div className=' no-header-model1234'>
                <div className='d-flex'>
                    <div></div>
                    <div className=' mt-2 me-2 ms-auto btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                        <img alt='' src={imagePath.ic_close}></img>
                    </div>
                </div>
                <div className='modal-header no-header-model123 d-flex'>
                    <div
                        className='mx-auto'
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '10px',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <img src={interviewheadericon} className='interview-header-icon'
                            style={{ height: '5vh' }}
                        />
                        <h2 className='interview-header-container-text'>{currentStage === newStage ? 'Reschedule Interview' : 'Schedule Interview'}</h2>
                    </div>

                </div>
                {/* end::Close */}
                <div className='d-flex justify-content-center'>
                    <label className='grey-chip px-12 form-label fs-7 fw-regular'>Panel </label>
                </div>

            </div>

            <div className='modal-body py-lg-8 px-lg-8'>
                <div className='card'>
                    {/* begin::Beader */}

                    <div className='card-body pb-0 mb-0 mt-0 pt-0 flex-row'>
                        <form
                            onSubmit={handleSubmit}
                            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                        >
                            <div className='form-row d-flex flex-column  mt-2 justify-content-spacearound'>

                                <div className='Input-Field-Container'>
                                    {/* <div
                                        style={{ padding: '0', margin: '0' }}
                                    >
                                        <img src={Usericon} className='slider-icon' />
                                        <hr className='hr-line-first-child' style={{ height: '75px' }}></hr>
                                        <img src={Timericon} className='slider-icon' />
                                        <hr className='hr-line-second-child' style={{ height: '20rem' }}></hr>
                                        <img src={Copyicon} className='slider-icon' />
                                        <hr className='hr-line-third-child' style={{ height: '2rem' }}></hr>
                                        <img src={Descriptionicon} className='slider-icon' />
                                    </div> */}

                                    <div>
                                        <div className='upper-input-container'>
                                            <div className='d-flex '>
                                                <img src={imagePath.ic_panel_members} width={48} height={48} className=' me-10' />
                                                <div className="input-container-upper-row" style={{ position: 'relative' }}>
                                                    <Box sx={{ width: 462 }}>
                                                        <FormControl fullWidth>
                                                            <div style={{ overflow: 'hidden' }}>
                                                                <input
                                                                    type="text"
                                                                    placeholder="Enter Interview Panelist Name/ Employee ID"
                                                                    className="request-approval-final-step-input-field"
                                                                    value={searchTerm}
                                                                    onChange={handleSearch}
                                                                    onKeyDown={handleKeyDown}
                                                                />
                                                                {searchRecord.length > 0 ? (
                                                                    <ul
                                                                        style={{
                                                                            border: '1px solid lightgrey',
                                                                            padding: '10px',
                                                                            display: 'flex',
                                                                            flexDirection: 'column',
                                                                            gap: '10px',
                                                                            zIndex: '2',
                                                                            backgroundColor: 'white',
                                                                            position: 'absolute',
                                                                            top: '100%',
                                                                            left: 0,
                                                                            width: '100%',
                                                                        }}
                                                                    >
                                                                        {searchRecord.map((interviewer, index) => (
                                                                            <li
                                                                                key={index}
                                                                                style={{
                                                                                    display: 'flex',
                                                                                    flexDirection: 'row',
                                                                                    gap: '10px',
                                                                                    alignItems: 'center',
                                                                                    cursor: 'pointer',
                                                                                    backgroundColor: highlightedIndex === index ? '#FFEBCE' : 'initial',
                                                                                    padding: '5px',
                                                                                    transition: 'background-color 0.3s',
                                                                                }}
                                                                                onClick={() => handleInterviewerSelect(interviewer)}
                                                                                onMouseEnter={(e) => {
                                                                                    (e.target as HTMLElement).style.backgroundColor = '#FFEBCE';
                                                                                }}
                                                                                onMouseLeave={(e) => {
                                                                                    (e.target as HTMLElement).style.backgroundColor = highlightedIndex === index ? '#FFEBCE' : 'initial';
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    src={interviewer['profile_pic'] !== null ? interviewer['profile_pic'] : UserIcon}
                                                                                    className="selected-interviewer-icon-sched"
                                                                                    alt=""
                                                                                />
                                                                                {`${interviewer['first_name']} - ${interviewer['employee_id']}`}
                                                                                <hr />
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                ) : (
                                                                    searchTerm !== '' && <p>No results found.</p>
                                                                )}
                                                            </div>
                                                        </FormControl>
                                                    </Box>
                                                    <label className='form-label fs-7 fw-bolder' style={{ color: '#F64E60' }}>Max 5 panelists can be selected per panel</label>
                                                    <div className="user-tile">
                                                        {selectedPanelMembers ? (
                                                            selectedPanelMembers.map((option, index) => (
                                                                <label className="user-tile-non-content d-flex align-items-center" key={index} style={{ borderRadius: '4px' }}>
                                                                    <img
                                                                        src={option.profile_pic !== null ? option.profile_pic : UserIcon}
                                                                        className="selected-interviewer-icon-sched me-2"
                                                                        alt=""
                                                                    />
                                                                    {`${option.first_name} - ${option.employee_id}`}
                                                                    <span>
                                                                        <img
                                                                            className='cursor-pointer ms-2'
                                                                            alt=""
                                                                            src={imagePath.ic_cross_orange}
                                                                            onClick={(event) => removeInterviewer(event, index)}
                                                                            id={JSON.stringify(option)}
                                                                        ></img>
                                                                    </span>
                                                                </label>
                                                            ))
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='d-flex'>
                                                <img src={imagePath.ic_time_orange_bg_circle} width={48} height={48} className=' me-10' />
                                                <div className='inner-input-contaier'>
                                                    <div className='upper-input-row-container'>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <Stack spacing={3}>
                                                                <Box sx={{ width: 228 }}>
                                                                    <DesktopDatePicker
                                                                        inputFormat='MM/DD/YYYY'
                                                                        value={value}
                                                                        label="Select Date"
                                                                        minDate={currentDate}
                                                                        onChange={handleChange}
                                                                        renderInput={(params) => (
                                                                            <TextField {...params} name='selectedDate'

                                                                            />
                                                                        )}
                                                                    />
                                                                </Box>
                                                            </Stack>
                                                        </LocalizationProvider>

                                                    </div>
                                                    <div className='d-flex'>
                                                        <label className='fs-7 fw-bolder form-label me-12'>Candidates</label>
                                                        <label className='fs-7 fw-bolder form-label ' style={{ marginLeft: '10rem' }}>Time Slot</label>
                                                    </div>
                                                    {/* show candidates and option to select time slot */}
                                                    {allCandidates && allCandidates.map((candidate, index) => {
                                                        return (
                                                            <div key={index} className='d-flex mb-4'>
                                                                <div className='d-flex align-items-center'>
                                                                    <img className='me-2' style={{ objectFit: 'cover', width: '34px', height: '34px', border: '1px solid #F36523', borderRadius: '17px' }}
                                                                        src={candidate['profile_pic'] ? candidate['profile_pic'] : UserIcon} />
                                                                    <label className='form-label fs-6 fw-regular me-4'>{`${candidate['first_name']} ${candidate['last_name']}`}</label>
                                                                </div>
                                                                <div className='d-flex align-items-center ms-auto'>
                                                                    <div className='d-flex align-items-center'>
                                                                        <label className='form-label fs-6 fw-regular me-2'>From</label>
                                                                        <Box
                                                                            sx={{
                                                                                width: 160,
                                                                            }}
                                                                        >
                                                                            <TextField
                                                                                id="time"
                                                                                label="Select Time"
                                                                                type="time"
                                                                                name="time_from"
                                                                                defaultValue="00:00"
                                                                                onChange={(event) => onTimeChange(event, index)}
                                                                                InputLabelProps={{
                                                                                    shrink: true,
                                                                                }}
                                                                                inputProps={{
                                                                                    step: 300, // 5 min
                                                                                }}
                                                                                onBlur={(event) => handleOnBlurTimeFrom(event, index)}
                                                                            />
                                                                        </Box>
                                                                    </div>
                                                                    <div className='d-flex align-items-center'>
                                                                        <label className='form-label fs-6 fw-regular me-2'>To</label>
                                                                        <Box
                                                                            sx={{
                                                                                width: 160,
                                                                            }}
                                                                        >
                                                                            <TextField
                                                                                id="time"
                                                                                label="Select Time"
                                                                                type="time"
                                                                                name="time_to"
                                                                                defaultValue="00:00"
                                                                                onChange={(event) => onTimeChange(event, index)}
                                                                                InputLabelProps={{
                                                                                    shrink: true,
                                                                                }}
                                                                                inputProps={{
                                                                                    step: 300, // 5 min
                                                                                }}
                                                                            />
                                                                        </Box>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        )
                                                    })
                                                    }
                                                    {/* <Box
                                                    sx={{
                                                        width: 228,
                                                    }}
                                                >
                                                    <FormControl fullWidth>
                                                        <InputLabel id='demo-simple-select-label'>Duration</InputLabel>
                                                        <Select
                                                            labelId='demo-simple-select-label'
                                                            id='demo-simple-select'
                                                            label='Durantion'
                                                            name="duration"
                                                            required
                                                            onChange={onInputChange}
                                                        >
                                                            <MenuItem value={10}>10 minutes</MenuItem>
                                                            <MenuItem value={20}>20 minutes</MenuItem>
                                                            <MenuItem value={30}>30 minutes</MenuItem>
                                                            <MenuItem value={40}>40 minutes</MenuItem>
                                                            <MenuItem value={50}>50 minutes</MenuItem>
                                                            <MenuItem value={60}>60 minutes</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Box> */}
                                                </div>
                                            </div>
                                            <div className='d-flex'>
                                                <img src={imagePath.ic_location_oranve_bg_circle} width={48} height={48} className=' me-10' />
                                                <div className='input-link-container'
                                                    style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
                                                >
                                                    <div className='schedule-interview-address'>
                                                        <div className='schedule-interview-address-buttons'>
                                                            <input
                                                                type='radio'
                                                                value="Online Interview"
                                                                name='interview'
                                                                className='schedule-interview-checkbox'
                                                                // onChange={onInputChange}
                                                                onChange={handelInterviewTypeChange}
                                                            />
                                                            <label>Online Interview</label>
                                                        </div>

                                                        <div className='schedule-interview-address-buttons'>
                                                            <input
                                                                type='radio'
                                                                value="Onsite Interview"
                                                                name='interview'
                                                                className='schedule-interview-checkbox'
                                                                // onChange={onInputChange}
                                                                onChange={handelInterviewTypeChange}
                                                            />
                                                            <label>Onsite Interview</label>
                                                        </div>

                                                    </div>
                                                    <Box
                                                        sx={{
                                                            width: 462,
                                                        }}
                                                    >
                                                        <TextField
                                                            fullWidth
                                                            name='invitation_link'
                                                            required
                                                            value={interviewAddress}
                                                            onChange={onHandleLinkChange}
                                                            placeholder={`${interviewType ? (interviewType === 'Online Interview' ? 'Enter meeting link' : 'Enter address') : 'Enter Credentials'}`}
                                                        />
                                                    </Box>
                                                </div>
                                            </div>
                                            {/* <div className='d-flex'>
                                                <img src={imagePath.ic_doc_orange_bg_circle} width={48} height={48} className=' me-10' />
                                                <div className='input-description-container'>
                                                    <Box
                                                        sx={{
                                                            width: 462,
                                                        }}
                                                    >
                                                        <TextField
                                                            fullWidth
                                                            label='Description'
                                                            onChange={handleInputs}
                                                            required
                                                            InputLabelProps={{ style: { fontWeight: 'bold', color: '#373D4A' } }}
                                                            disabled
                                                        />

                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            width: 462,
                                                            height: 80,
                                                        }}
                                                    >

                                                        <ReactQuill
                                                            value={description}
                                                            onChange={onDescriptionChange}
                                                            placeholder="Type Here"
                                                            modules={{
                                                                toolbar: [
                                                                    [{ header: [1, 2, 3, 4, false] }],
                                                                    ['bold', 'italic', 'underline', 'strike'],
                                                                    [{ color: [] }, { background: [] }],
                                                                    [{ align: [] }],
                                                                    ['link'
                                                                    ],
                                                                    ['clean'],
                                                                ],
                                                            }}
                                                            formats={[
                                                                'header',
                                                                'bold',
                                                                'italic',
                                                                'underline',
                                                                'strike',
                                                                'color',
                                                                'background',
                                                                'align',
                                                                'link',
                                                            ]}
                                                            style={{ height: '90px' }}

                                                        />

                                                    </Box>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='inputfield-lower-container d-flex align-items-center'>
                                <img src={InformationIcon} style={{ width: '3%', marginTop: '2px' }} />
                                <label className='inputfield-lower-container-text'>
                                    By clicking on send the above interview details will be sent to Candidate & all
                                    Panel Member via email
                                </label>
                            </div>
                            <div className='inputfield-button-container'>
                                {!loading ? (
                                    <input className='inputfield-btn' type='submit' name='Send' value='Submit' onClick={handleSubmit} />
                                ) : (
                                    <FontAwesomeIcon icon={faSpinner} color='#F36523' spin size="2x" />
                                )}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {errorMessage && (
                <div
                    style={{
                        position: 'fixed',
                        bottom: '0',
                        left: '0',
                        width: '100%',
                        padding: '10px',
                        backgroundColor: '#FFE2E5',
                        color: '#F64E60',
                        textAlign: 'center',
                        fontWeight: '700'
                    }}
                >
                    {errorMessage}
                </div>
            )}

            {showErrorMessage && (
                <ShowError
                    handleClose={() => setShowErrorMessage(false)}
                    message={message}
                />
            )}

        </Modal>
    );
};

//make this component available to the app
export default ScheduleInterViewMultipleCandidatesStage1;
