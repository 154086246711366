import React, { useRef, useImperativeHandle, forwardRef } from 'react'
import { useState, useEffect } from 'react'
import { RejectUserbyApplicationInterview } from '../../../../../_metronic/partials/modals/JobFunnelsModels/RejectUserApplicationInterview'
import { ApproveUserApplicationInterviewStage2 } from '../../../../../_metronic/partials/modals/JobFunnelsModels/ApproveUserApplicationStage2'
import { ApproveAllUserApplicationInterviewStage2 } from '../../../../../_metronic/partials/modals/JobFunnelsModels/ApproveAllUserApplicationInterviewStage2'
import { RejectAllUserApplicationInterview } from '../../../../../_metronic/partials/modals/JobFunnelsModels/RejectAllUserApplicationInterview'
import { AddScoreInterviewPopupStage2 } from '../../../../../_metronic/partials/modals/JobFunnelsModels/AddScoreInterviewPopupStage2'
import unchecked from '../../images/Group 10062.png'
import checked from '../../images/checked.png'
import './InterViewMainScreen.css'
import threeedota from '../../images/menu.png'
import axios from 'axios'
import { useAuth } from '../../../auth'
import AvatarIcon from '../../img/Group 9681.png'
import { ReScheduleInterviewStage2 } from '../../../../../_metronic/partials/modals/JobFunnelsModels/ReScheduleInterviewStage2'
import { InterviewDetailsStage2 } from '../../../../../_metronic/partials/modals/JobFunnelsModels/InterviewDetailsStage2'
import { ApproveUserAppInterviewStag2App } from '../../../../../_metronic/partials/modals/JobFunnelsModels/ApproveUserAppInterviewStag2App'
import { ApproveUserAppInterviewStage1App } from '../../../../../_metronic/partials/modals/JobFunnelsModels/ApproveUserAppInterviewStage1App'
import UserIcon from '../../img/Group 9681.png'
import PlusIcon from '../../img/Group 12561@2x.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { ScheduleInterviewPopUpStage3 } from '../../../../../_metronic/partials/modals/JobFunnelsModels/ScheduleInterviewStage3'
import ViewProfileApplication from '../../../../../_metronic/partials/modals/JobFunnelsModels/ViewProfileApplication'
import ShowCurrentStatus from '../../../../../_metronic/partials/modals/JobFunnelsModels/ShowCurrentStatus'
import ShowError from '../../../../../_metronic/partials/content/toasts/ShowError'
import InterviewScoringDetails from '../../../../../_metronic/partials/modals/JobFunnelsModels/InterviewScoringDetails'
import imagePath from '../../../../../constants/imagePath'
import CustomPagination from '../../../dashboards/components/CustomPagination'

interface Resource {
  candidate_details: Object
  resource: Object
}

const API_URL = process.env.REACT_APP_API_URL
export const GET_ALL_CANDIDATES = `${API_URL}/candidate`

export const GET_ALL_JOB_APPLICATIONS = `${API_URL}/jobapplication/get-current-job-application`

export const GET_CANDIDATE_DATA = `${API_URL}/candidate/`

export const APPROVE_CANDIDATE_DATA = `${API_URL}/jobapplication/accept-current-job-application`

export const FETCH_AVG_SCORE = `${API_URL}/jobapplication/set-stages-interview-average-score`

export const FETCH_PANEL_PROFILE = `${API_URL}/panel/get-panel-images`

type Props = {
  job_id: any
  interviewStages: any
  trigger: any
  avgScore: any
  getSelectedApplications: (candidateApplication) => void
  showScheduleInterview: (actionType) => void
  cancelInterviewIndividual: () => void
}

export type Stage2Ref = {
  GetAllJobsApplicationsbypage: () => void
}

// function Stage2({ job_id, interviewStages, trigger, avgScore, getSelectedApplications }: Props) {
const Stage2 = React.forwardRef(
  ({ job_id, interviewStages, trigger, avgScore, getSelectedApplications, showScheduleInterview, cancelInterviewIndividual }: Props, ref) => {
    console.log({ interviewStages })
    const [Approvalpopup, setpopupApproval] = useState(<></>)
    const [popup, setpopup] = useState(<></>)
    const [approveAllPopup, setApproveAllPopup] = useState(<></>)
    const [rejectAllPopup, setRejectAllPopup] = useState(<></>)
    const [addScorepop, setaddScorepop] = useState(<></>)
    const [selectedPopup, setSelectedPopup] = useState(false)
    const [Master, setMaster] = useState(false)
    const [checkedstate, setcheckedstate] = useState(false)
    const [multioptiondots, setmultioptiondots] = useState(false)
    const [StateMulti, setsetState] = useState()
    const [ApplicationData, setApplicationData] = useState<any>([])
    const [result, setResult] = useState<any>([])
    const [candidateEntityData, setCandidateEntityData] = useState([])
    const [popshow, setpopshow] = useState(true)
    const { auth, logout } = useAuth()
    const [isOpen, setIsOpen] = useState<any>([])
    const [popupReschedule, setpopupReschedule] = useState<any>(<></>)
    const dropdownRef = useRef<HTMLDivElement>(null)
    const [popupInterviewDetails, setpopupInterviewDetails] = useState<any>(<></>)
    const [popupApprovalCase, setpopupApprovalCase] = useState<any>(<></>)
    const [popupApprovalCase2, setpopupApprovalCase2] = useState<any>(<></>)
    const [popupProfile, setpopupProfile] = useState(<></>)
    // const [avgScore, setAvgScore] = useState<any>([])
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [jobTitle, setJobTitle] = useState<any>()
    const [show, setShow] = useState<any>(true)
    const [isLoading, setIsLoading] = useState(false)
    const [newPanelImages, setNewPanelImages] = useState<any>([])
    const [clicked, setClicked] = useState<any>(false)
    const [individualPercentages, setIndividualPercentages] = useState<any>([])
    const approveButtonRefs = useRef<(HTMLButtonElement | null)[]>([])
    const [currentJobAppId, setCurrentJobAppId] = useState('')
    const [showStatusPopup, setShowStatusPopup] = useState(false)
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [message, setMessage] = useState('')
    const [selectedApplicants, setSelectedApplicants] = useState<any>([])
    const [totalPages, setTotalPages] = useState(1)
    const [params, setParams] = useState<any>({
      order: 'updated_at',
      sort: 'DESC',
      page: 1,
      limit: 10,
      total: 0,
      age: '',
      bankingExp: '',
      totalExp: '',
      universities: ''
    })

    useImperativeHandle(ref, () => ({
      GetAllJobsApplicationsbypage,
    }))

    const createApprovalPopupCase3 = (name, jobapp_id, email, candidate_id, interviewStages, profilePic, position, bexp, jexp) => {
      setpopupApprovalCase2(
        <ApproveUserAppInterviewStag2App
          show={true}
          name={name}
          jobapp_id={jobapp_id}
          email={email}
          candidate_id={candidate_id}
          interviewStages={interviewStages}
          profilePic={profilePic}
          position={position}
          bexp={bexp}
          jexp={jexp}
          handleClose={() => {
            setpopupApprovalCase2(<></>)
            GetAllJobsApplicationsbypage()
          }}
        />
      )
    }

    const createApprovalPopupCase2 = (name, jobapp_id, email, candidate_id) => {
      setpopupApprovalCase(
        <ApproveUserAppInterviewStage1App
          show={true}
          name={name}
          jobapp_id={jobapp_id}
          email={email}
          candidate_id={candidate_id}
          handleClose={() => {
            setpopupApprovalCase(<></>)
            GetAllJobsApplicationsbypage()
          }}
        />
      )
    }

    const createReschedulePopup = (name, jobapp_id, email, user_id, interviewStage1, profile_pic, position, bexp, jexp) => {
      setpopupReschedule(
        <ReScheduleInterviewStage2
          show={true}
          name={name}
          jobapp_id={jobapp_id}
          email={email}
          user_id={user_id}
          interviewStage1={interviewStage1}
          profile_pic={profile_pic}
          position={position}
          bexp={bexp}
          jexp={jexp}
          handleClose={() => {
            setpopupReschedule(<></>)
            GetAllJobsApplicationsbypage()
          }}
        />
      )
      console.log('popup', jobapp_id)
    }

    const createProfilePopup = (
      name,
      jobapp_id,
      email,
      user_id,
      profile_pic,
      cnic,
      phone_number,
      education,
      skills,
      work_Experience,
      total_exp,
      banking_exp,
      nonBanking_exp,
      educations,
      certifications,
      personalData,
      profile_match,
      position,
      recuiter_id,
      videoResumeUrl,
      testExists,
      index
    ) => {
      setpopupProfile(
        <ViewProfileApplication
          show={true}
          name={name}
          jobapp_id={jobapp_id}
          user_id={user_id}
          email={email}
          profile_pic={profile_pic}
          cnic={cnic}
          phone_number={phone_number}
          education={education}
          skills={skills}
          work_Experience={work_Experience}
          total_exp={total_exp}
          banking_exp={banking_exp}
          nonBanking_exp={nonBanking_exp}
          educations={educations}
          certifications={certifications}
          personalData={personalData}
          profile_match={profile_match}
          position={position}
          recuiter_id={recuiter_id}
          videoResumeUrl={videoResumeUrl}
          testExists={testExists}
          applicationStage={'stage'}
          index={index}
          handleClose={() => {
            setpopupProfile(<></>)
            GetAllJobsApplicationsbypage()
          }}
          recaller={() => recaller(index)}
        />
      )
    }

    const recaller = (index) => {
      setClicked(index)
    }

    useEffect(() => {
      if (clicked !== null) {
        if (approveButtonRefs.current[clicked]) {
          approveButtonRefs.current[clicked]?.click() // Use optional chaining to safely trigger the click event
        }
        setClicked(null) // Reset the clicked index
      }
    }, [clicked])

    const createAddScorePop = (name, jobapp_id, job_id, profile_pic, position, bexp, jexp) => {
      setaddScorepop(
        <AddScoreInterviewPopupStage2
          name={name}
          jobapp_id={jobapp_id}
          job_id={job_id}
          profile_pic={profile_pic}
          position={position}
          bexp={bexp}
          jexp={jexp}
          handleClose={() => {
            setaddScorepop(<></>)
            setpopshow(false)
            GetAllJobsApplicationsbypage()
          }}
          fetchScore={
            () => {

            }
            // FETCHSCORE
          }
        />
      )
    }

    const showScoresPopup = (jobapp_id, stage, avgScore, preferredGrade) => {
      setaddScorepop(
        <InterviewScoringDetails
          show={true}
          stage={stage}
          jobapp_id={jobapp_id}
          avgScore={avgScore}
          preferredGrade={preferredGrade}
          handleClose={() => {
            setaddScorepop(<></>)
            setpopshow(false)
            GetAllJobsApplicationsbypage()
          }}
        />
      )
    }

    const createInterviewDetailsPopup = (name, jobapp_id, email, user_id, interviewStage1, profile_pic, position, bexp, jexp) => {
      setpopupInterviewDetails(
        <InterviewDetailsStage2
          show={true}
          name={name}
          jobapp_id={jobapp_id}
          email={email}
          user_id={user_id}
          interviewStage1={interviewStage1}
          profile_pic={profile_pic}
          position={position}
          bexp={bexp}
          jexp={jexp}
          handleClose={() => {
            setpopupInterviewDetails(<></>)
          }}
        />
      )
      console.log('popup', jobapp_id)
    }

    const createPopup = (name, jobapp_id) => {
      setpopup(
        <RejectUserbyApplicationInterview
          show={true}
          name={name}
          jobapp_id={jobapp_id}
          handleClose={() => {
            setpopup(<></>)
            GetAllJobsApplicationsbypage()
          }}
        />
      )
    }

    const createPopupSTAGE3 = (name, jobapp_id, email, user_id, interviewStages, profile_pic, position, bexp, jexp) => {
      setpopup(
        <ScheduleInterviewPopUpStage3
          show={true}
          name={name}
          jobapp_id={jobapp_id}
          email={email}
          user_id={user_id}
          interviewStages={interviewStages}
          profile_pic={profile_pic}
          position={position}
          bexp={bexp}
          jexp={jexp}
          handleClose={() => {
            setpopup(<></>)
            GetAllJobsApplicationsbypage()
          }}
        />
      )
    }

    const createApprovalPopup = (name, jobapp_id, email, user_id, interviewStages, profile_pic, position, bexp, jexp) => {
      setpopupApproval(
        <ApproveUserApplicationInterviewStage2
          show={true}
          name={name}
          jobapp_id={jobapp_id}
          email={email}
          user_id={user_id}
          interviewStages={interviewStages}
          profile_pic={profile_pic}
          position={position}
          bexp={bexp}
          jexp={jexp}
          handleClose={() => {
            setpopupApproval(<></>)
            GetAllJobsApplicationsbypage()
            setShow(false)
            createPopupSTAGE3(name, jobapp_id, email, user_id, interviewStages, profile_pic, position, bexp, jexp)
          }}
        />
      )
    }

    const createApproveAllPopup = (jobapp_id) => {
      setApproveAllPopup(
        <ApproveAllUserApplicationInterviewStage2
          show={true}
          jobapp_id={jobapp_id}
          handleClose={() => {
            setApproveAllPopup(<></>)
            GetAllJobsApplicationsbypage()
          }}
        />
      )
      console.log('popup', jobapp_id)
    }

    const createRejectAllPopup = (jobapp_id) => {
      setRejectAllPopup(
        <RejectAllUserApplicationInterview
          show={true}
          jobapp_id={jobapp_id}
          handleClose={() => {
            setRejectAllPopup(<></>)
            GetAllJobsApplicationsbypage()
          }}
        />
      )
    }

    const GetAllJobsApplicationsbypage = async () => {
      try {
        setIsLoading(true)
        axios.post(GET_ALL_JOB_APPLICATIONS,
          {
            job_id,
            getStage: 'stage2',
            limit: params.limit,
            page: params.page,
            order: params.order,
            sort: params.sort,
          },
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'Content-Type': 'application/json',
            },
          }
        ).then(async (resp) => {
          setIsLoading(false)
          setJobTitle(resp?.data?.title)
          setNewPanelImages(resp?.data?.panelImages)
          setIndividualPercentages(resp?.data?.membersScore)
          const jobApplications = resp?.data?.jobs
          let pagination = resp?.data?.pagination
          if (pagination) {
            if (params.page !== pagination.page || params.limit !== pagination.limit || params.total !== pagination.total) {
              let paramsData = { ...params }
              paramsData.page = pagination?.page || 1
              paramsData.limit = pagination?.limit || 10
              paramsData.total = pagination?.total || 0
              setParams(paramsData)
            }
            setTotalPages(pagination?.totalPages || 0)
          }
          setResult(jobApplications)

        }).catch((error) => {
          if (error.response && error.response.status === 400) {
            // showToast(error.response.data.message)
            setMessage(error.response.data.message)
            setShowErrorMessage(true)
          } else if (
            error.response &&
            error.response.status === 401 &&
            error.response.data &&
            error.response.data.name === 'TokenExpiredError'
          ) {
            logout()
          } else if (error.response && error.response.data && error.response.data.error) {
            if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
              logout()
            } else {
              setMessage(error.response.data.error.message)
              setShowErrorMessage(true)
            }
          } else {
            setMessage(error.message)
            setShowErrorMessage(true)
          }
        })

      } catch (error) {
        console.log(error)
      } finally {
        // Set isLoading to false when data fetching is complete
        setIsLoading(false);
      }
    }

    const handlePageChange = (page) => {
      let data = { ...params }
      data.page = page
      setParams(data)
    }
    const handleItemsPerPageChange = (event) => {
      let data = { ...params }
      data.limit = event.target.value
      setParams(data)
    }

    //Approve API
    const FETCHSCORE = async () => {
      axios
        .post(
          FETCH_AVG_SCORE,
          {
            job_id: job_id,
            jobapp_stage: 'stage2',
          },
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'Content-Type': 'application/json',
            },
          }
        )
        .then((res) => {
          console.log({ res })
          // setAvgScore(res?.data?.[0])
        })
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            // showToast(error.response.data.message)
            setMessage(error.response.data.message)
            setShowErrorMessage(true)
          } else if (
            error.response &&
            error.response.status === 401 &&
            error.response.data &&
            error.response.data.name === 'TokenExpiredError'
          ) {
            logout()
          } else if (error.response && error.response.data && error.response.data.error) {
            if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
              logout()
            } else {
              setMessage(error.response.data.error.message)
              setShowErrorMessage(true)
            }
          } else {
            setMessage(error.message)
            setShowErrorMessage(true)
          }
        })
    }
    useEffect(() => {
      // FETCHSCORE()
    }, [])

    useEffect(() => {
      (async () => {
        await GetAllJobsApplicationsbypage();
      })()
    }, [params, trigger, popup, popupReschedule])

    // useEffect(() => {
    //   // Call GETAVGSCORE initially
    //   GETAVGSCORE();

    //   // Call GETAVGSCORE every second
    //   const interval = setInterval(() => {
    //     GETAVGSCORE();
    //   }, 1000);

    //   // Clean up the interval when the component unmounts
    //   return () => clearInterval(interval);
    // }, [result]);

    //   const {loggedIn, setLoggedIn} = useContext(LoginContext)
    const checkedStateFunction = () => {
      setcheckedstate(!checkedstate)
    }

    const DisplayAllAoptiontoadopt = () => {
      setmultioptiondots(!multioptiondots)
    }

    useEffect(() => {
      function handleClickOutside(event) {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setIsOpen(false)
        }
      }

      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [dropdownRef])

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
      // Close all overlays if clicked outside of any overlay or its trigger
      const clickedElement = event.target as HTMLElement;
      const isInsideOverlay = clickedElement.closest('.overlay');
      const isInsideTrigger = clickedElement.closest('.cursor-pointer');
      if (!isInsideOverlay && !isInsideTrigger) {
        setIsOpen(new Array(isOpen.length).fill(false));
      }
    };

    const handleImageClick = (index) => {
      const updatedOpenState = [...isOpen]
      updatedOpenState[index] = !updatedOpenState[index]
      setIsOpen(updatedOpenState)
    }

    const isApplicantSelected = (candidateId) => {
      // return selectedApplicants.includes(candidateId);
      return selectedApplicants.some((applicant) => applicant?.candidateData?.user_id === candidateId)
    }

    const updateSelectedApplicants = (candidateApplication) => {
      let candidateId = candidateApplication?.candidateData?.user_id
      // Check if candidateId is already in the list
      const isSelected = selectedApplicants.some((applicant) => applicant?.candidateData?.user_id === candidateId)
      // If already selected, remove it; otherwise, add it
      if (isSelected) {
        const updatedList = selectedApplicants.filter((applicant) => applicant?.candidateData?.user_id !== candidateId)
        setSelectedApplicants(updatedList)
        getSelectedApplications(updatedList)
      } else {
        // also check is the total selected applications are 7 do not add into list and show warning message else add application into list
        const totalSelected = selectedApplicants.length
        if (totalSelected === 7) {
          setMessage('Maximum limit reached. Cannot add more applicants.')
          setShowErrorMessage(true);
          return
        }
        let applications = [...selectedApplicants]
        applications.push(candidateApplication)
        console.log('selectedApplications: updatedList2: ', applications)
        setSelectedApplicants(applications)
        // setSelectedApplicants(prevList => [...prevList, candidateApplication]);
        getSelectedApplications(applications)
      }
    }

    return (
      <>
        <div className='card-body py-3' onClick={handleClick}>
          <div style={{ position: 'relative' }}>
            {isLoading && (
              // Show loading icon if isLoading state is true
              <div
                className='position-absolute w-100 h-100 d-flex justify-content-center align-items-center'
                style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', top: 0, left: 0, zIndex: 999 }}
              >
                <FontAwesomeIcon icon={faSpinner} color='#F36523' spin size='4x' />
              </div>
            )}
            <div className='table-responsive'>
              <table className='table align-middle gs-0 gy-4 table-borders'>
                <thead>
                  <tr className='fw-bold graycolor bg-light'>
                    <th style={{ padding: '0px 0px 11px 20px' }} className='min-w-20px align-middle'></th>
                    <th className='min-w-100px align-items-center ps-2 align-middle' scope='col'>
                      <label className=' form-label fw-bolder fs-6 me-2'>Candidate</label>
                    </th>
                    <th className='min-w-100px align-items-center align-middle' style={{ whiteSpace: 'nowrap' }} scope='col'>
                      <label className=' form-label fw-bolder fs-6 me-2'>CNIC</label>
                    </th>
                    <th className='min-w-100px align-items-center align-middle' scope='col'>
                      <label className=' form-label fw-bolder fs-6 me-2'>Interview Panel</label>
                    </th>
                    <th className='min-w-100px align-items-center align-middle' scope='col'>
                      <label className=' form-label fw-bolder fs-6 me-2'>Date & Time</label>
                    </th>
                    <th className='min-w-100px align-items-center align-middle' scope='col'>
                      <label className=' form-label fw-bolder fs-6 me-2'>Status</label>
                    </th>
                    <th className='min-w-100px align-items-center align-middle' scope='col'>
                      <label className=' form-label fw-bolder fs-6 me-2'>Interview Assessment</label>
                    </th>
                    <th className='min-w-100px align-items-center align-middle' scope='col'>
                      <label className=' form-label fw-bolder fs-6 me-2'>Order Of Preference</label>
                    </th>
                    <th className='min-w-100px align-items-center align-middle' scope='col'>
                      <label className=' form-label fw-bolder fs-6 me-2'>Actions</label>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {result && result?.map((candidategData, index) => {
                    let candidateId = candidategData?.candidateData?.user_id
                    let rowSelected = isApplicantSelected(candidateId)
                    const isEvenRow = index % 2 === 0
                    return (
                      <tr style={{ backgroundColor: isEvenRow ? 'white' : '#FCFCFC', borderBottom: '1px solid #EDF2F9' }}>
                        <td className='firstrowmarginindex'>
                          {candidategData?.jobapp_status !== 'WITHDRAWN' ? (
                            <div
                              style={{ cursor: 'pointer' }}
                              // onClick={() => checkedStateFunction()}
                              onClick={() => updateSelectedApplicants(candidategData)}
                            >
                              {rowSelected === false ? (
                                <>
                                  {' '}
                                  <img src={unchecked}></img>
                                </>
                              ) : (
                                <>
                                  {' '}
                                  <img src={checked}></img>
                                </>
                              )}
                            </div>
                          ) : (
                            <div></div>
                          )}
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='symbol symbol-45px me-2'>
                              <img
                                className='profile-user-icon cursor-pointer'
                                src={
                                  candidategData?.candidate?.profile_pic !== null
                                    ? candidategData?.candidate?.profile_pic
                                    : candidategData?.candidateData?.profile_pic !== null
                                      ? candidategData?.candidateData?.profile_pic
                                      : imagePath.userIcon
                                }
                                alt=''
                                onClick={() => {
                                  createProfilePopup(
                                    candidategData?.candidateData?.fullName,
                                    candidategData?.jobapp_id,
                                    candidategData?.candidateData?.email,
                                    candidategData?.candidateData?.user_id,
                                    candidategData?.candidateData?.profile_pic,
                                    candidategData?.candidateData?.cnic,
                                    candidategData?.candidateData?.phone_number,
                                    candidategData?.institute,
                                    candidategData?.candidate?.skills,
                                    candidategData?.candidate?.work_experience,
                                    // parseInt(candidategData?.job_experience) + parseInt(candidategData?.banking_experience),
                                    candidategData?.candidate?.totalExperience,
                                    candidategData?.banking_experience,
                                    candidategData?.job_experience,
                                    candidategData?.candidate?.qualification,
                                    candidategData?.candidate?.certification,
                                    candidategData?.candidateData?.dob,
                                    candidategData?.profile_match,
                                    jobTitle,
                                    candidategData?.recuiter_id,
                                    candidategData?.video_resume,
                                    true,
                                    index
                                  )
                                }}
                              />
                            </div>
                            <div className='d-flex justify-content-start flex-column'>
                              <label className='job-funneling-table-content '>
                                {candidategData?.candidateData?.fullName}
                              </label>
                            </div>
                          </div>
                        </td>
                        <td>
                          <label className='job-funneling-table-content ' >{candidategData?.candidateData?.cnic}</label>
                        </td>
                        <td>
                          <div className=' experienceJobstable '>
                            <div
                              className='panel-member-icon-details cursor-pointer'
                              // onClick={() => {
                              //   createInterviewDetailsPopup(
                              //     candidategData?.candidateData?.fullName,
                              //     candidategData?.jobapp_id,
                              //     candidategData?.candidateData?.email,
                              //     candidategData?.candidateData?.user_id,
                              //     candidategData?.schedule_interview_data_stage2,
                              //     candidategData?.candidate?.profile_pic,
                              //     jobTitle,
                              //     candidategData?.candidate?.banking_experience,
                              //     candidategData?.candidate?.job_experience
                              //   );
                              // }}
                              onClick={() => {
                                setCurrentJobAppId(candidategData?.jobapp_id)
                                setShowStatusPopup(true)
                              }}
                            >
                              {candidategData?.panelImages && candidategData?.panelImages.map((panel, panelIndex) => {
                                return (
                                  <img
                                    key={panelIndex}
                                    src={panel?.profilePic ? panel?.profilePic : imagePath?.userIcon}
                                    alt={panel?.name}
                                    className='panel-member-icon-details-icon'
                                  />
                                )
                              })}
                              {/* <img src={PlusIcon} className='panel-member-icon-details-icon' /> */}
                            </div>
                            <React.Fragment>{popupInterviewDetails}</React.Fragment>
                          </div>
                        </td>
                        <td>
                          <div className='job-funneling-table-content '>
                            {(() => {
                              if (candidategData?.schedule_interview_data_stage2) {
                                const interviewObject = JSON?.parse(candidategData?.schedule_interview_data_stage2)
                                const interviewTime = interviewObject?.interview_time.split(':')
                                // const interviewDate = new Date(interviewObject?.interview_date);
                                const interviewDate = new Date(interviewObject?.interviewDate)
                                const interviewDateTime = new Date(
                                  interviewDate?.getFullYear(),
                                  interviewDate?.getMonth(),
                                  interviewDate?.getDate(),
                                  parseInt(interviewTime[0]),
                                  parseInt(interviewTime[1]),
                                  0
                                )
                                const formattedTime = interviewDateTime?.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' })
                                const formattedDate = interviewDateTime?.toLocaleDateString('en-US')
                                const ampm = interviewDateTime?.toLocaleTimeString('en-US', { hour: 'numeric', hour12: true })
                                return (
                                  <>
                                    <div>{formattedDate}</div>
                                    <div>{formattedTime}</div>
                                  </>
                                )
                              } else {
                                return 'No interview data available'
                              }
                            })()}
                          </div>
                        </td>

                        <td className='text-end'>
                          <div className='d-flex flex-column w-100 me-2'>
                            <div className='d-flex flex-stack mb-2'>
                              <span className='text-mutedjgfk me-2 fs-7 fw-semibold'>
                                <div
                                  key={candidategData.jobapp_id}
                                  className={candidategData.jobapp_status === 'Interview Conducted' ? 'interview-conducted' : 'red-chip fs-7'}
                                >
                                  {/* Display the avgScore value here */}
                                  {candidategData?.jobapp_status ? candidategData?.jobapp_status : 'Scoring Pending'}
                                </div>
                              </span>
                            </div>
                          </div>
                        </td>

                        <td
                        >
                          <div
                            className='dateApplicationtable'
                            style={{ alignItems: 'center' }}
                          >
                            <label className='testscore-approval'>{candidategData?.interview_stage2_score}%</label>
                          </div>

                          {candidategData?.interview_stage2_score === '0.00' ||
                            candidategData?.interview_stage2_score === null ? (
                            <div>
                              {individualPercentages && individualPercentages.length > 0 && (
                                <div>
                                  {individualPercentages.map((data, index) => {
                                    if (data?.jobapp_id === candidategData?.jobapp_id) {

                                      return (
                                        <div key={index}>
                                          {data.memberScore !== null && data.memberScore !== 0 && (
                                            <label className='testscore-approval'>{data.memberScore}%</label>
                                          )}
                                        </div>
                                      )
                                    } else {
                                      return null // or any other fallback/rendering you desire if the condition is not met
                                    }
                                  })}
                                </div>
                              )}
                            </div>
                          ) : null}
                        </td>

                        <td>
                          <div className='d-flex align-items-center'>
                            <label className='grey-chip me-2'>{`${candidategData?.preferredGrade ? candidategData?.preferredGrade : '0'}`}</label>
                            <label
                              className='fs-6 fw-bolder form-label'
                              style={{ color: '#F36523', cursor: 'pointer', whiteSpace: 'nowrap' }}
                              onClick={() => {
                                showScoresPopup(
                                  candidategData?.jobapp_id,
                                  candidategData?.jobapp_stage,
                                  candidategData?.average_interview_score,
                                  candidategData?.preferredGrade
                                )
                              }}
                            >
                              View Detail
                            </label>
                          </div>
                        </td>

                        <td >
                          <div className='d-flex align-items-center justify-content-center me-4'>

                            <div className='d-flex align-items-center justify-content-center '>
                              <div >
                                {candidategData?.jobapp_status !== 'WITHDRAWN' ? (
                                  <button
                                    onClick={() => {
                                      let applicants: any = []
                                      applicants.push(candidategData)
                                      setSelectedApplicants(applicants)
                                      getSelectedApplications(applicants)
                                      showScheduleInterview('approval')

                                    }}
                                    className='Approvebuttononline'
                                    disabled={
                                      candidategData?.jobapp_stage === "stage2" &&
                                      candidategData?.jobapp_status !== "Interview Conducted"

                                    }
                                    ref={(button) => (approveButtonRefs.current[index] = button)}
                                  >
                                    Approve
                                  </button>
                                ) : (
                                  <div>
                                    <label className='red-chip fs-7 fw-bolder form-label'>Withdrawn</label>
                                  </div>
                                )}
                              </div>
                            </div>{' '}
                            {candidategData?.jobapp_status !== 'Interview Conducted' && (
                              <div
                                key={index}
                                style={{
                                  position: 'relative',
                                }}
                              >
                                <img src={threeedota} alt='threedots' onClick={() => handleImageClick(index)} />
                                {isOpen[index] && (
                                  <div
                                    className='overlay'
                                    style={{
                                      padding: '8px',
                                    }}
                                  >
                                    <a
                                      className='liststyling fw-semibold fs-6'
                                      onClick={() => {
                                        let applicants: any = []
                                        applicants.push(candidategData)
                                        setSelectedApplicants(applicants)
                                        getSelectedApplications(applicants)
                                        showScheduleInterview('reScheduleInterviews')

                                        const updatedOpenState = [...isOpen]
                                        updatedOpenState[index] = false
                                        setIsOpen(updatedOpenState)
                                      }}
                                    >
                                      Reschedule
                                    </a>
                                    <a>
                                      <div
                                        className=' liststyling fw-semibold fs-6 mt-2'
                                        onClick={() => {
                                          let applicants: any = []
                                          applicants.push(candidategData)
                                          setSelectedApplicants(applicants)
                                          getSelectedApplications(applicants)
                                          cancelInterviewIndividual()

                                          const updatedOpenState = [...isOpen]
                                          updatedOpenState[index] = false
                                          setIsOpen(updatedOpenState)
                                        }}
                                      >
                                        Cancel
                                      </div>
                                    </a>
                                  </div>
                                )}
                              </div>
                            )}
                            {parseInt(interviewStages) === 3 && Approvalpopup}
                            {parseInt(interviewStages) === 1 && popupApprovalCase}
                            {parseInt(interviewStages) === 2 && popupApprovalCase2}
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
                {popupReschedule}
                {popupProfile}
                {addScorepop}
                {popup}
              </table>
              {/* pagination */}
              {(result && result.length > 0) && (<div className='d-flex col-md-12 align-items-center'>
                <div className='col-md-4'>
                  <label>{`Showing ${result ? result.length : 0} of ${params.total} results.`}</label>
                </div>
                <div className='mx-auto col-md-4' style={{ textAlign: 'center' }}>
                  <CustomPagination
                    pageValue={params.page}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                </div>

                <div className='col-md-4 '>
                  <div className='ms-auto d-flex flex-end align-items-center'>
                    <select
                      className='me-2 pagination-input'
                      style={{ backgroundColor: '#FFFFFF', borderRadius: '2px' }}
                      id='selectValues'
                      value={params.limit}
                      onChange={handleItemsPerPageChange}
                    >
                      <option value='10'>10</option>
                      <option value='25'>25</option>
                      <option value='50'>50</option>
                    </select>

                    <div className='me-2'>
                      <label>Items per page</label>
                    </div>
                  </div>
                </div>
              </div>)}
            </div>
          </div>
        </div>
        {showStatusPopup && (
          <ShowCurrentStatus
            show={true}
            jobAppId={currentJobAppId}
            stage={'stage2'}
            handleClose={() => {
              setShowStatusPopup(false)
            }}
          />
        )}
        {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}
      </>
    )
  }
)
export default Stage2
