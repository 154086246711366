// src/pages/Error404.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../modules/auth';

const Error404 = () => {
    const navigate = useNavigate();
    const { currentUser } = useAuth();

    const handleGoBack = () => {
        if (currentUser) {
            if (currentUser.role === 'masterRecruiter' || currentUser.role === 'ta_head' || currentUser.role === 'ta_lead' || currentUser.role === 'recruiter') {
                navigate('/dashboard'); // Redirect to home page
            } else if (currentUser.role === 'poc') {
                navigate('/pocdashboard');
            } else if (currentUser.role === 'none') {
                navigate('/employeeDashboard');
            } else if (currentUser.role === 'candidate') {
                navigate('/candidatedashboard');
            } else {
                navigate('/auth'); // Redirect to auth page
            }
        } else {
            navigate('/auth'); // Redirect to auth page
        }
    };

    return (
        // <div style={{ textAlign: 'center', marginTop: '50px' }}>
        //     <h1>404 - Page Not Found</h1>
        //     <p>The page you are looking for does not exist.</p>
        //     <button onClick={handleGoBack}>Go Back</button>
        // </div>
        <div className="error-page">
            <div className="error-container">
                <h1 className="error-title text-white">404 - Page Not Found</h1>
                <p className="error-description fs-5 text-white">
                    Oops! The page you are looking for does not exist. It might have been moved or deleted or you does not have access to that page.
                </p>
                <p className="error-suggestion fs-5 text-white">
                    Please check the URL and try again. If you think this is a mistake, contact support for assistance.
                </p>
                <button className="candi-btn2 btn text-white" onClick={handleGoBack}>Go Back</button>
            </div>
        </div>
    );
};

export default Error404;
