//import liraries
import React, { useEffect, useState } from 'react';

// import Layout from '../images/Layout-4-blocks (3).png'
import JobHeaderIcon from '../../../_metronic/partials/modals/JobFunnelsModels/img/job.png'
import BOPLOGO from '../../../_metronic/partials/modals/JobFunnelsModels/img/BOP_ Logo (shadow)@2x.png'
import { useAuth } from '../../../app/modules/auth/core/Auth'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom';
import DownLoadIcon from '../../../app/modules/JobsFunneling/img/Component 237 – 1.png';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import LoaderView from '../../../_metronic/partials/modals/JobFunnelsModels/LoaderView';
import ShowError from '../../../_metronic/partials/content/toasts/ShowError';
import OfferLetterObjectionPopup from '../../../_metronic/partials/modals/JobFunnelsModels/OfferLetterObjectionPopup';

const API_URL = process.env.REACT_APP_API_URL
// const ACCEPT_OFFER_LETTER_URL = `${API_URL}/panel/approve-candidate`;
const CANDIDATE_ACCEPT_OFFER_LETTER_URL = `${API_URL}/panel/change-panel-status`;
const ACCEPT_OFFER_LETTER_URL = `${API_URL}/panel/send-email-to-ta`;
const REJECT_OFFER_LETTER_URL = `${API_URL}/panel/change-panel-status`;
const GET_OFFER_LETTER_DATA = `${API_URL}/jobapplication/get-offer-letter-data`;
const GET_CONFIGURATIONS_URL = `${API_URL}/configuration/get-configuration`;

// create a component
const OfferLetterExternal = () => {
    const { currentUser, auth, logout } = useAuth();
    const navigate = useNavigate();
    let { member_id, jobapp_id, index } = useParams();
    const [loading, setLoading] = useState(false);
    const [candidateData, setCandidateData] = useState<any>();
    const [jobData, setJobData] = useState<any>();
    const [batchPositions, setBatchPositions] = useState<any>();
    const [batchId, setBatchId] = useState('');
    const [jobAppData, setJobAppData] = useState<any>();
    const [offerLetter, setOfferLetter] = useState<any>();
    const [disabledBtn, setDisabledBtn] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [message, setMessage] = useState('');
    const [showObjectionPopup, setShowObjectionPopup] = useState(false);
    const [configurationId, setConfigurationId] = useState<any>('');
    const [benefits, setBenefits] = useState<any>([])
    const [fetchingBenefits, setFetchingBenefits] = useState(false);

    const getConfigurations = async () => {
        setFetchingBenefits(true);
        await axios.get(GET_CONFIGURATIONS_URL
            // , {
            //     headers: {
            //         Authorization: `Bearer ${auth?.token}`,
            //         'Content-Type': 'application/json',
            //     },
            // }
        ).then((res) => {
            setFetchingBenefits(false);
            let data = res?.data?.Configuration;
            setConfigurationId(data?.configuration_id);
            let benefits = data?.benefits;
            if (benefits !== null && benefits !== undefined) {
                benefits = JSON.parse(benefits);
                const initialData = Object.keys(benefits).map((key) => ({
                    grade: benefits[key]['name'],
                    file: null,
                    fileUrl: benefits[key]['link']
                }));
                setBenefits(initialData);
            }

        }).catch((error) => {
            setFetchingBenefits(false);
            if (error.response && error.response.status === 400) {
                // showToast(error.response.data.message)
                setMessage(error.response.data.message);
                setShowErrorMessage(true);
            } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
                logout();
            } else if (error.response && error.response.data && error.response.data.error) {
                if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                    logout();
                } else {
                    setMessage(error.response.data.error.message);
                    setShowErrorMessage(true);
                }
            } else {
                setMessage(error.message);
                setShowErrorMessage(true);
            }
        });
    };

    const getOfferLetterData = async () => {
        let data = {
            jobapp_id: jobapp_id,
            jobapp_stage: "hired"
        };
        axios.post(GET_OFFER_LETTER_DATA, data
            //     , {
            //     headers: {
            //         Authorization: `Bearer ${auth?.token}`,
            //         'Content-Type': 'application/json',
            //     },
            // }
        ).then((res) => {
            let responseData = res.data;
            setCandidateData(responseData?.candidateData);
            setJobData(responseData?.JobData);
            setJobAppData(responseData?.JobAppData);
            let offer_letter_data = JSON.parse(responseData?.JobAppData?.offer_letter);
            console.log('offerLetterData: ', offer_letter_data);
            setOfferLetter(offer_letter_data);
            setBatchId(responseData?.JobData?.position_id);
            setBatchPositions(res?.data?.batchPositions);


        }).catch((error) => {
            if (error.response && error.response.status === 400) {
                // showToast(error.response.data.message)
                setMessage(error.response.data.message);
                setShowErrorMessage(true);
            } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
                logout();
            } else if (error.response && error.response.data && error.response.data.error) {
                if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                    logout();
                } else {
                    setMessage(error.response.data.error.message);
                    setShowErrorMessage(true);
                }
            } else {
                setMessage(error.message);
                setShowErrorMessage(true);
            }
        });
    }

    useEffect(() => {
        (async () => {
            // if (auth?.token) {
            getConfigurations();
            getOfferLetterData();
            // } else {
            //     navigate('/');
            // }
        })()
    }, [])

    const rejectOfferLetter = (note) => {

        setLoading(true);
        let data = {
            jobapp_id: jobapp_id,
            status: "rejected",
            name: "hired",
            member_id: member_id,
            objection_notes: note
        };
        axios.post(REJECT_OFFER_LETTER_URL, data
            // , {
            //     headers: {
            //         Authorization: `Bearer ${auth?.token}`,
            //         'Content-Type': 'application/json',
            //     },
            // }
        ).then((res) => {
            setLoading(false);
            let responseData = res.data;
            setDisabledBtn(true)
            closeCurrentPage(); // close the current page after a while
        }).catch((error) => {
            setLoading(false);
            if (error.response && error.response.status === 400) {
                // showToast(error.response.data.message)
                setMessage(error.response.data.message);
                setShowErrorMessage(true);
            } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
                logout();
            } else if (error.response && error.response.data && error.response.data.error) {
                if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                    logout();
                } else {
                    setMessage(error.response.data.error.message);
                    setShowErrorMessage(true);
                }
            } else {
                setMessage(error.message);
                setShowErrorMessage(true);
            }
        });
    };

    const acceptOfferLetter = (event) => {
        event.preventDefault();
        setLoading(true);
        let data = {
            jobapp_id: jobapp_id,
            status: "accepted",
            name: "hired",
            index: index
        };
        axios.post(ACCEPT_OFFER_LETTER_URL, data
            //     , {
            //     headers: {
            //         Authorization: `Bearer ${auth?.token}`,
            //         'Content-Type': 'application/json',
            //     },
            // }
        ).then((res) => {
            setLoading(false);
            let responseData = res.data;
            setDisabledBtn(true);
            closeCurrentPage(); // close the current page after a while
        }).catch((error) => {
            setLoading(false);
            if (error.response && error.response.status === 400) {
                // showToast(error.response.data.message)
                setMessage(error.response.data.message);
                setShowErrorMessage(true);
            } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
                logout();
            } else if (error.response && error.response.data && error.response.data.error) {
                if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                    logout();
                } else {
                    setMessage(error.response.data.error.message);
                    setShowErrorMessage(true);
                }
            } else {
                setMessage(error.message);
                setShowErrorMessage(true);
            }
        });
    };

    const closeCurrentPage = () => {
        // Wait for 5 seconds and close the current page
        setTimeout(() => {
            // window.close();
            navigate('/');
        }, 3000);
    };

    const getLocationValue = () => {
        let location = '';
        if (batchId) {
            const matchingPosition = batchPositions.find((position) => position.position_id === batchId);
            if (matchingPosition) {
                location = matchingPosition.location;
            }
        }
        console.log('foundLocation: ', location)
        return location;
    };

    const handleDownloadBenefitsPdf = (e) => {
        e.preventDefault();
        if (benefits !== null && benefits !== undefined && benefits.length > 0) {
            if (jobData?.grade !== null && jobData?.grade !== undefined && jobData?.grade !== '') {
                // Find the object with the specified grade
                let benefit = benefits.find(item => item.grade === jobData?.grade);
                // Extract the fileUrl value if the grade is found
                let fileUrl = benefit ? benefit.fileUrl : null;
                if (fileUrl) {
                    // Create a temporary anchor element
                    const link = document.createElement('a');
                    link.href = fileUrl;
                    link.target = '_blank'; // Open the PDF in a new tab
                    link.setAttribute('download', ''); // Set the download attribute to force download
                    document.body.appendChild(link);
                    link.click(); // Click the anchor element to initiate download
                    document.body.removeChild(link); // Remove the anchor element from the document
                } else {
                    setMessage('Benefits sheet not available contact admin to add Benefits sheet.');
                    setShowErrorMessage(true);
                }
            } else {
                setMessage('Grade value not found, Please try again.');
                setShowErrorMessage(true);
            }

        } else {
            setMessage('List of benefits sheets not found, Please Try again.');
            setShowErrorMessage(true);
        }
    }

    return (
        <div className='card-bg shadow-sm px-10 py-6' style={{ backgroundColor: '#FFFFFF', }}>
            <div id='candidate-offer-letter-view mx-10 my-6 col-md-8'>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                    }}
                >
                    <img src={JobHeaderIcon} className="score-header-icon-internally" alt="Job Header Icon" />
                    <h2 className="offer-letter-header-container-text mt-1">Offer Letter</h2>
                </div>
                <div className=' no-header-model123'>
                    <div className=' d-flex flex-column my-8 '>
                        <img alt='' style={{ objectFit: 'cover', width: '84px', alignSelf: 'center' }} src={BOPLOGO}></img>
                    </div>
                </div>
                <div className='d-flex align-items-center px-10'>
                    <img className='profile-user-icon-request-approval-doc me-4' src={candidateData?.profile_pic} width={44} height={44} style={{ objectFit: 'cover' }} />
                    <label className='fw-bolder fs-4'>{`${candidateData?.first_name} ${candidateData?.last_name}`}</label>
                    {/* <label className='fw-bolder fs-7' style={{ marginLeft: 'auto' }}>{`Issued Date: ${new Date(offerLetter?.issueDate).toLocaleDateString('en-GB')}`}</label> */}
                </div>
                <label className='fw-normal fs-7 px-10 mt-4 mb-10'>{candidateData?.address}</label>
                <div className='px-10'>
                    <label className='offer-letter-address-cont'>{`Dear ${candidateData?.prefix} ${candidateData?.first_name}`}</label>
                    <br />
                    <div className='offer-letter-detailed-text'>
                        We are pleased to offer you employment with the Bank as
                        <span className='offer-letter-address-cont'
                            style={{ marginLeft: '10px' }}
                        >
                            {jobData?.title}
                        </span>
                    </div>
                    <label className='offer-letter-detailed-text'>This offer is subjected to acceptability of the following terms & conditions:</label>

                    <div className='offer-letter-final-body'>
                        <div>
                            <label className='offer-letter-address-cont'>Initial Place of Posting</label>
                            <br />
                            <p className='offer-letter-detailed-text'>
                                {/* {jobData?.location} */}
                                {/* {`${batchPositions ? getLocationValue() : jobData?.location}`} */}
                                {`${jobAppData?.posting_place}`}
                            </p>
                        </div>

                        <div>
                            <label className='offer-letter-address-cont'>Salary & Allowance </label>
                            <>
                                <div className='col-md-6 mx-auto' >
                                    <div className='offer-letter-extra-allowance'>
                                        <p className='offer-letter-allowance-name'>Basic Pay (BP)</p>
                                        <p className='offer-letter-value'>Rs. {offerLetter?.BasicPay?.toLocaleString()}</p>
                                    </div>
                                    <div>
                                        {(offerLetter?.allowance && offerLetter.allowance?.length > 0) ? offerLetter?.allowance?.map((item, index) => {
                                            return (
                                                <div key={index}>
                                                    <div className='offer-letter-extra-allowance'>
                                                        {item.selectedValue !== 0 ? (
                                                            <>
                                                                <p className='offer-letter-allowance-name'>
                                                                    {item.name} @ {item.selectedValue}% of BP
                                                                </p>
                                                                <p className='offer-letter-value'>Rs.{item?.value ? (item?.value?.toLocaleString()) : 0}</p>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <p className='offer-letter-allowance-name'>{item.name} of BP</p>
                                                                <p className='offer-letter-value'>Rs.{item?.value ? (item?.value?.toLocaleString()) : 0}</p>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            )
                                        }) : (<div></div>)}
                                    </div>
                                </div>

                                <div className='offer-letter-lower-bar'>
                                    <label>Gross Salary:  Rs. {offerLetter?.GrossSalary?.toLocaleString()} </label>
                                </div>
                            </>
                            <>
                                <div className='col-md-6 mx-auto'>
                                    <div className='offer-letter-extra-allowance'>
                                        <div className='offer-letter-allowance-name'>Fuel Facility </div>
                                        <div className='offer-letter-value'>Rs.{offerLetter?.FuelFacility ? (offerLetter?.FuelFacility?.toLocaleString()) : 0}</div>
                                    </div>
                                    <div className='offer-letter-extra-allowance'>
                                        <div className='offer-letter-allowance-name'> Car Allowance  </div>
                                        <div className='offer-letter-value'> Rs.{offerLetter?.CarAllowance ? (offerLetter?.CarAllowance?.toLocaleString()) : 0}</div>
                                    </div>
                                    <div className='offer-letter-extra-allowance'>
                                        <div className='offer-letter-allowance-name'> Bonus  </div>
                                        <div className='offer-letter-value'> Rs.{offerLetter?.bonus ? (offerLetter?.bonus?.toLocaleString()) : 0}</div>
                                    </div>
                                </div>

                                <div className='offer-letter-lower-bar'>
                                    <label> Salary Per Month:  Rs. {offerLetter?.Salary?.toLocaleString()}</label>
                                </div>
                            </>
                            <div className='d-flex align-items-center mb-8'>
                                <label className='fw-bolder fs-4 col-md-3'>Benefits & Incentives</label>
                                {fetchingBenefits ? (
                                    <div className='d-flex align-items-center'>
                                        <FontAwesomeIcon className='me-4' icon={faSpinner} spin color='#F36523' size='2x' />
                                        <label>Fetching Benefits info...</label>

                                    </div>
                                ) : (
                                    <div className='request-for-approvals-external-files-text'>
                                        <div>
                                            <button onClick={handleDownloadBenefitsPdf} style={{ border: 'none', backgroundColor: 'transparent' }}>
                                                <img src={DownLoadIcon} />
                                            </button>
                                        </div>
                                        <div>
                                            <label>Benefits & Incentives.pdf</label>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                    </div>

                    <div
                        className='me-8'
                        style={{ paddingLeft: '1em' }}>
                        {ReactHtmlParser(offerLetter?.offerLetterContent)}
                    </div>

                    <div className='d-flex'>
                        <div className='col-md-6' style={{ display: 'flex', flexDirection: 'column' }}>
                            <label className='form-label fw-normal fs-5 mb-10' style={{ display: 'block' }}>Sincerely,</label>
                            <label className='form-label fw-normal fs-5 mt-10' style={{ display: 'block' }}>For & on behalf of;</label>
                            <label className='form-label fw-normal fs-5 mb-10' style={{ display: 'block' }}>The Bank of Punjab,</label>

                            <label className='form-label fw-bolder fs-5 mt-10' style={{ display: 'block' }}>Head Talent Acquisition</label>
                        </div>
                        {/* <div className='col-md-6' style={{ display: 'flex' }}>
                            <img src={JobHeaderIcon} className="score-header-icon-internally" alt="Job Header Icon" style={{ alignSelf: 'flex-end' }} />
                        </div> */}
                    </div>
                    <div className='offer-letter-joining-date-cont'>
                        {/* <label className='offer-letter-joining-date'> * Joining Date: {offerLetter?.joining_date}*</label> */}
                        {/* <label className='offer-letter-joining-date'> * Joining Date: {new Date(offerLetter?.joining_date).toLocaleDateString('en-GB')}*</label> */}
                        {/* <label className='offer-letter-joining-date'> * Expiry Date: {new Date(offerLetter?.offerletterExpiryDate).toLocaleDateString('en-GB')}*</label> */}
                        <label className='offer-letter-joining-date'> * Expiry Date: *</label>

                    </div>
                </div>

            </div>
            <div className='modal-body py-lg-8 px-lg-8'>
                <form >
                    <div className='btn-container d-flex'>
                        <button
                            onClick={(event) => {
                                // show popup to ask for reason
                                event.preventDefault();
                                setShowObjectionPopup(true);
                                // rejectOfferLetter(event)

                            }}
                            className=' px-10 py-4 hollow-btn fw-bolder fs-5 me-4'
                            disabled={disabledBtn}
                        >
                            Reject
                        </button>
                        <button
                            onClick={(event) => acceptOfferLetter(event)}
                            className='btn px-10 apply-review-button text-white'
                            disabled={disabledBtn}
                        >
                            {`${index === '3' ? 'Accept' : 'Approve'}`}
                        </button>
                    </div>
                </form>
            </div>

            {showObjectionPopup && (
                <OfferLetterObjectionPopup
                    handleClose={() => setShowObjectionPopup(false)}
                    handleYesSure={(note) => {
                        if (note === null || note === undefined || note === '') {
                            setMessage('Rejection reason can not be empty')
                            setShowErrorMessage(true);
                            return;
                        }
                        setShowObjectionPopup(false)
                        rejectOfferLetter(note);
                    }}

                />
            )}
            {loading && (
                <LoaderView
                    message='Loading...'
                />
            )}
            {showErrorMessage && (
                <ShowError
                    handleClose={() => setShowErrorMessage(false)}
                    message={message}
                />
            )}
        </div>
    );
};

//make this component available to the app
export default OfferLetterExternal;
